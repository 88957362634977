import React from 'react'
import PropTypes from 'prop-types'
import { Typography, message } from 'antd'
import { formatDate, getDate, isDateExpired } from '../../helpers/time'
import { useEditChatDate, useFollowerVipChats } from '../../api/users'
import EditableTable from '../Layout/Editable/EditableTable'
import { format } from 'date-fns'
import { appConfig } from '../../constants/appConfig'

const { Title } = Typography

function VipChatList({ userId }) {
  const { data, isLoading } = useFollowerVipChats(userId, {
    enabled: Boolean(userId),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const { mutate: editChatDate } = useEditChatDate({
    onSuccess: () => {},
    onError: () => message.error('Не удалось изменить дату премиум чата'),
  })

  const columns = [
    {
      title: 'Инфлюенсер',
      dataIndex: ['abonent', 'nickName'],
      key: 'nickName',
    },
    {
      title: 'Срок действия',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      editable: true,
      editableField: {
        type: 'DatePicker',
      },
      render: (expirationDate) => (
        <div className={isDateExpired(expirationDate) ? 'text-error' : 'text-success'}>
          {formatDate(expirationDate)}
        </div>
      ),
    },
  ]

  const handleSave = (newData, oldData) => {
    if (
      newData?.expirationDate &&
      getDate(newData?.expirationDate).toString() !== getDate(oldData.expirationDate).toString()
    ) {
      editChatDate({
        followerId: userId,
        influencerId: oldData.abonent?.idProfile,
        date: format(getDate(newData.expirationDate), appConfig.dateFormats.apiDateTimeT),
      })
    }
  }

  return (
    <div className="expense-list">
      <Title level={4}>Премиум чаты</Title>
      <EditableTable
        rowKey="chatId"
        pagination={false}
        loading={isLoading}
        originColumns={columns}
        originData={data?.data?.data || []}
        onSave={handleSave}
      />
    </div>
  )
}

VipChatList.propTypes = {
  userId: PropTypes.string,
}

export default VipChatList
