import { api, queryClient } from './api'
import { useMutation, useQuery } from 'react-query'
import { INFLUENCERS_KEY } from './influencers'
import { USERS_KEY } from './users'

export const USER_INFO_KEY = 'user_information'
export const PAYMENT_INFO_KEY = 'payment_information'

const getAdminList = () => {
  return api.get({
    path: `/insider/administrator/admins`,
  })
}

export const useAdminList = () => {
  return useQuery('adminList', () => {
    return getAdminList()
  })
}

const deleteAdmin = (id) => {
  return api.del({
    path: `/insider/administrator/admin?userId=${id}`,
  })
}

export const useDeleteAdmin = (onSuccess, onError) => {
  return useMutation(deleteAdmin, {
    onSuccess: (data, variables) => {
      onSuccess(data, variables)
    },
    onError: () => onError(),
  })
}

const addAdmin = ({ email }) => {
  return api.post({
    path: `/insider/administrator/admin?email=${email}`,
  })
}

export const useAddAdmin = (onSuccess, onError) => {
  return useMutation(addAdmin, {
    onSuccess: () => {
      onSuccess()
    },
    onError: () => onError(),
  })
}

const getPaymentsList = (data) => {
  return api.post({
    path: `/insider/administrator/payments`,
    data,
  })
}

export const usePaymentList = ({ page, ...params }) => {
  return useQuery(
    [
      'paymentsList',
      page,
      params.dateFrom,
      params.dateTo,
      params.influencerNick,
      params.followerNick,
      params.orderId,
      params.paymentId,
      params.paymentType,
      params.pan,
      params.showAllPayments,
    ],
    () => {
      return getPaymentsList({ perPage: 20, page, ...params })
    },
    { keepPreviousData: true },
  )
}

const getAgentPaymentStats = ({ influencerId, dateFrom, dateTo }) => {
  const params = new URLSearchParams({
    influencerId,
    dateFrom,
    dateTo,
  })
  return api.get({
    path: `/insider/administrator/payment-stats?${params.toString()}`,
  })
}

export function useMutateAgentPaymentStats() {
  return useMutation(getAgentPaymentStats)
}

const changeSubscriptionPrice = ({ influencerId, data }) => {
  return api.put({
    path: `/insider/administrator/influencer/${influencerId}/change-price`,
    data,
  })
}

export function useMutateChangeSubscriptionPrice() {
  return useMutation(changeSubscriptionPrice, {
    onSuccess: () => {
      queryClient.refetchQueries(USER_INFO_KEY)
    },
  })
}

const changeChatPrice = ({ influencerId, data }) => {
  return api.put({
    path: `/insider/administrator/influencer/${influencerId}/chat-price`,
    data,
  })
}

export function useMutateChangeChatPrice() {
  return useMutation(changeChatPrice, {
    onSuccess: () => {
      queryClient.refetchQueries(USER_INFO_KEY)
    },
  })
}

const hardBanUser = ({ followerId, ban = true }) => {
  const params = new URLSearchParams({ ban })
  return api.put({
    path: `/insider/administrator/hard-ban/${followerId}?${params.toString()}`,
  })
}

export function useMutateHardBanUser() {
  return useMutation(hardBanUser, {
    onSuccess: () => {
      queryClient.refetchQueries(USER_INFO_KEY, { active: true })
      queryClient.refetchQueries(USERS_KEY, { active: true })
    },
  })
}

const changeAgentData = ({ influencerId, data }) => {
  return api.put({
    path: `/insider/administrator/agent/${influencerId}`,
    data,
  })
}

export function useMutateAgentData() {
  return useMutation(changeAgentData)
}

const getAgentData = ({ influencerId }) => {
  return api.get({
    path: `/insider/administrator/agent/${influencerId}`,
  })
}

export function useMutateGetAgentData() {
  return useMutation(getAgentData)
}

const renewLogs = ({ influencerId, fromDate, toDate }) => {
  const params = new URLSearchParams({
    ...(influencerId && { influencerId }),
    ...(fromDate && { fromDate }),
    ...(toDate && { toDate }),
  })
  return api.get({
    path: `/insider/administrator/renew-logs?${params}`,
  })
}

export const useRenewLogs = ({ influencerId, fromDate, toDate }) => {
  return useQuery(['renewLogs', influencerId, fromDate, toDate], () => {
    return renewLogs({ influencerId, fromDate, toDate })
  })
}

const returnPayment = ({ paymentId }) => {
  return api.del({
    path: `/insider/administrator/payment/${paymentId}/delete`,
  })
}

export const useReturnPayment = ({ onSuccess, onError }) => {
  return useMutation(returnPayment, {
    onSuccess: (data, variables) => {
      onSuccess(data, variables)
    },
    onError: () => onError(),
  })
}

const getUserInformation = ({ userId }) => {
  const params = new URLSearchParams({
    ...(userId && { userId }),
  })
  return api.get({
    path: `/insider/administrator/userinformation?${params}`,
  })
}

export const useUserInformation = ({ userId }, params) => {
  return useQuery(
    [USER_INFO_KEY, userId],
    () => {
      return getUserInformation({ userId })
    },
    params,
  )
}

export const toggleInfluencerActive = (influencerId) => {
  return api.post({
    path: `/insider/administrator/influencer/toggle-active?influencerId=${influencerId}`,
  })
}

export const useToggleInfluencerActive = (options) => {
  return useMutation(toggleInfluencerActive, {
    onSuccess: () => {
      queryClient.refetchQueries(USER_INFO_KEY)
    },
    ...options,
  })
}

export const addInfluencer = ({ email, password = null, userId = null }) => {
  return api.post({
    path: `/insider/administrator/influencer/add`,
    data: {
      email,
      ...(password && { password }),
      ...(userId && { userId }),
    },
  })
}

export const useAddInfluencer = (options) => {
  return useMutation(addInfluencer, {
    onSuccess: () => {
      queryClient.refetchQueries(INFLUENCERS_KEY)
    },
    ...options,
  })
}

export const addLikes = ({ blogPostId, likesToAdd }) => {
  return api.put({
    path: `/insider/administrator/${blogPostId}/add-likes`,
    data: { likesToAdd },
  })
}

export const useAddLikes = (options) => {
  return useMutation(addLikes, options)
}

export const getPaymentInformation = ({ orderId }) => {
  return api.get({
    path: `/insider/administrator/orderid?customOrderId=${orderId}`,
  })
}

export const usePaymentInformation = ({ orderId }, params) => {
  return useQuery(
    [PAYMENT_INFO_KEY, orderId],
    () => {
      return getPaymentInformation({ orderId })
    },
    params,
  )
}
