import React from 'react'
import { Typography, Table, Button, Modal, message } from 'antd'
import { useAddAdmin, useAdminList, useDeleteAdmin } from '../../api/administrator'
import AddAdmin from './AddAdmin'
const { Title } = Typography

function AdminsList() {
  const { data, refetch } = useAdminList()

  const { mutate: deleteAdmin } = useDeleteAdmin(
    () => {
      refetch()
    },
    () => {
      message.error('Не удалось удалить роль админа')
    },
  )
  const { mutate: addAdmin } = useAddAdmin(
    () => {
      refetch()
    },
    () => {
      message.error('Не удалось добавить администратора')
    },
  )
  const handleRow = () => {
    return {
      onClick: () => {},
    }
  }

  const columns = [
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Удалить',
      key: 'actions',
      render: (row) => {
        return (
          <Button
            size="small"
            onClick={() => {
              Modal.confirm({
                content: `Удалить роль админа у ${row.email}?`,
                onOk() {
                  deleteAdmin(row.id)
                },
              })
            }}
          >
            Удалить роль админа
          </Button>
        )
      },
    },
  ]
  const dataSource = Object.entries(data?.data || {}).map(([id, email]) => ({
    id,
    email,
  }))

  return (
    <div className="users">
      <Title level={3}>
        Администраторы <AddAdmin addAdmin={addAdmin} />
      </Title>
      <Table pagination={false} columns={columns} dataSource={dataSource} onRow={handleRow} />
    </div>
  )
}

export default AdminsList
