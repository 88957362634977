import React from 'react'
import PropTypes from 'prop-types'
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons'
import { nbsp } from '../../constants/typography'

function FlagValue({ value, onlyIcon }) {
  return value ? (
    <>
      <CheckCircleFilled style={{ color: 'green' }} />
      {!onlyIcon && `${nbsp}${value}`}
    </>
  ) : (
    <CloseCircleFilled style={{ color: 'red' }} />
  )
}

FlagValue.defaultProps = {
  onlyIcon: false,
}

FlagValue.propTypes = {
  value: PropTypes.any,
  onlyIcon: PropTypes.bool.isRequired,
}

export default FlagValue
