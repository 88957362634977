import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { DatePicker, Spin, Form, Input, Button, message } from 'antd'
import { appConfig } from '../../constants/appConfig'
import moment from 'moment'
import { useMutateCreateInfluencerPayments } from '../../api/influencerPayments'

function IncomeForm({ influencerId, onSave }) {
  const [form] = Form.useForm()

  const {
    mutate: createInfluencerPayments,
    isLoading: isLoadingPayments,
    isError: isErrorPayments,
    isSuccess: isSuccessPayments,
  } = useMutateCreateInfluencerPayments()

  useEffect(() => {
    if (isSuccessPayments) {
      onSave()
    } else if (isErrorPayments) {
      message.error('Ошибка сохранения данных')
    }
  }, [isErrorPayments, isSuccessPayments])

  const handleFinish = (values) => {
    createInfluencerPayments({
      influencerId,
      ...values,
    })
  }

  const handleSave = () => {
    form.submit()
  }

  const validateSum = async (rule, value) => {
    if (!value) {
      throw new Error('Введите сумму')
    }
    if (!(value >= 0)) {
      throw new Error('Сумма должна быть числом')
    }
  }

  return (
    <Spin spinning={isLoadingPayments}>
      <Form
        form={form}
        onFinish={handleFinish}
        requiredMark={false}
        layout="inline"
        className="income-form"
      >
        <Form.Item
          name="date"
          label="Дата"
          rules={[{ required: true, message: 'Введите дату' }]}
          initialValue={moment()}
        >
          <DatePicker
            format={appConfig.dateFormats.shortDate}
            size="small"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item name="amount" label="Сумма" rules={[{ validator: validateSum }]}>
          <Input size="small" />
        </Form.Item>
        <Form.Item
          name="paymentData"
          label="Ид банковской операции"
          rules={[{ required: true, message: 'Введите значение' }]}
        >
          <Input size="small" />
        </Form.Item>
        <Form.Item>
          <Button size="small" type="primary" onClick={handleSave}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}

IncomeForm.defaultProps = {
  onSave: () => {},
}

IncomeForm.propTypes = {
  influencerId: PropTypes.string,
  onSave: PropTypes.func,
}

export default IncomeForm
