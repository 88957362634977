import React from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../../constants/appConfig'
import { DatePicker } from 'antd'

const { RangePicker } = DatePicker

function ColumnDateSearch({ title, onChange, value }) {
  return (
    <>
      {title}
      <br />
      <RangePicker format={appConfig.dateFormats.shortDate} onChange={onChange} value={value} />
    </>
  )
}

ColumnDateSearch.defaultProps = {
  title: 'Дата',
  onChange: () => {},
}

ColumnDateSearch.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.array,
}

export default ColumnDateSearch
