import axios from 'axios'
import { QueryClient } from 'react-query/core'
import { ROUTES } from '../constants/routes'
import { auth } from './auth'

const apiEndpoint = ''

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 3 * 60 * 1000,
    },
  },
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    console.error('API error:', error)
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      auth.removeToken()
      window.location.href = ROUTES.AUTH
    }
    return Promise.reject(error)
  },
)

const getHeaders = ({ contentType = 'application/json' }) => ({
  Accept: 'application/json',
  'Content-type': contentType,
})

const get = ({ path, data, headers = {}, prefixEndpoint = apiEndpoint }) =>
  makeRequest({ method: 'GET', path, data, prefixEndpoint, headers })
const post = ({ path, data, headers = {}, prefixEndpoint = apiEndpoint }) =>
  makeRequest({ method: 'POST', path, data, prefixEndpoint, headers })
const put = ({ path, data, headers = {}, prefixEndpoint = apiEndpoint }) =>
  makeRequest({ method: 'PUT', path, data, prefixEndpoint, headers })
const del = ({ path, data, headers = {}, prefixEndpoint = apiEndpoint }) =>
  makeRequest({ method: 'DELETE', path, data, prefixEndpoint, headers })

const makeRequest = ({ method, path, data, prefixEndpoint, headers }) =>
  axios.request({
    url: prefixEndpoint + path,
    method,
    data,
    headers: getHeaders(headers),
  })

export const api = {
  get,
  post,
  put,
  del,
}
