import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Button, Input, Row, Col, Typography, Spin, message } from 'antd'
import { simpleReducer } from '../../helpers'
import { appConfig } from '../../constants/appConfig'
import { rub } from '../../constants/typography'
import { useMutateChangeSubscriptionPrice } from '../../api/administrator'

const modes = {
  edit: 'edit',
  show: 'show',
}

const { Text } = Typography
const { types: periodTypes } = appConfig.subscribe
const ERROR_COST = 'Стоимость подписки может быть от 0 до '

function SubscriptionData({ subscription, influencerId }) {
  const initialState = {
    mode: modes.show,
    oneMonthCost: subscription?.oneMonthCost,
    threeMonthCost: subscription?.threeMonthCost,
    twelveMonthCost: subscription?.twelveMonthCost,
    error1: null,
    error3: null,
    error12: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    mutate: changeSubscriptionPrice,
    isSuccess: isSuccessPrice,
    isError: isErrorPrice,
    isLoading: isLoadingPrice,
  } = useMutateChangeSubscriptionPrice()

  useEffect(() => {
    if (isSuccessPrice) {
      setState({ mode: modes.show })
    } else if (isErrorPrice) {
      message.error('Ошибка сохранения данных')
    }
  }, [isSuccessPrice, isErrorPrice])

  useEffect(() => {
    setState({
      oneMonthCost: subscription?.oneMonthCost,
      threeMonthCost: subscription?.threeMonthCost,
      twelveMonthCost: subscription?.twelveMonthCost,
    })
  }, [subscription])

  const handleButton = () => {
    if (state.mode === modes.show) {
      setState({ mode: modes.edit })
    } else {
      changeSubscriptionPrice({
        influencerId,
        data: [
          { subscriptionTypeKey: periodTypes.oneMonthKey, subscriptionPrice: state.oneMonthCost },
          {
            subscriptionTypeKey: periodTypes.threeMonthKey,
            subscriptionPrice: state.threeMonthCost,
          },
          {
            subscriptionTypeKey: periodTypes.twelveMonthsKey,
            subscriptionPrice: state.twelveMonthCost,
          },
        ],
      })
    }
  }

  const handleCancel = () => {
    setState(initialState)
  }

  const getErrorMessage = (maxCost) => `${ERROR_COST} ${maxCost} ${rub}`

  const getPeriodLimit = (periodKey) => {
    if (periodKey === periodTypes.oneMonthKey) {
      return appConfig.subscribe.firstLimit
    } else if (periodKey === periodTypes.threeMonthKey) {
      return state.oneMonthCost * 3
    } else if (periodKey === periodTypes.twelveMonthsKey) {
      return state.threeMonthCost * 4
    }
    return 0
  }

  const isValidCost = (value, periodKey) => {
    const cost = parseInt(value)
    return 0 <= cost && cost <= getPeriodLimit(periodKey)
  }

  const handleChangeCost = (value, periodKey) => {
    const digits = value?.replace(/([^\d])/, '')
    const error = isValidCost(digits, periodKey) ? null : getErrorMessage(getPeriodLimit(periodKey))

    if (periodKey === periodTypes.oneMonthKey) {
      setState({ oneMonthCost: digits, error1: error })
    } else if (periodKey === periodTypes.threeMonthKey) {
      setState({ threeMonthCost: digits, error3: error })
    } else if (periodKey === periodTypes.twelveMonthsKey) {
      setState({ twelveMonthCost: digits, error12: error })
    }
  }

  return (
    <Spin spinning={isLoadingPrice}>
      <div className="subscription">
        <Row className="subscription-row">
          <Col span={14}>
            <b>Подписка 1 мес:</b>
          </Col>
          <Col span={10}>
            {state.mode === modes.show ? (
              state.oneMonthCost
            ) : (
              <Input
                value={state.oneMonthCost}
                size="small"
                onChange={(event) => handleChangeCost(event.target.value, periodTypes.oneMonthKey)}
              />
            )}
          </Col>
          {state.error1 && (
            <div>
              <Text type="danger">{state.error1}</Text>
            </div>
          )}
        </Row>
        <Row className="subscription-row">
          <Col span={14}>
            <b>Подписка 3 мес:</b>
          </Col>
          <Col span={10}>
            {state.mode === modes.show ? (
              state.threeMonthCost
            ) : (
              <Input
                value={state.threeMonthCost}
                size="small"
                onChange={(event) =>
                  handleChangeCost(event.target.value, periodTypes.threeMonthKey)
                }
              />
            )}
          </Col>
          {state.error3 && (
            <div>
              <Text type="danger">{state.error3}</Text>
            </div>
          )}
        </Row>
        <Row className="subscription-row">
          <Col span={14}>
            <b>Подписка 12 мес:</b>
          </Col>
          <Col span={10}>
            {state.mode === modes.show ? (
              state.twelveMonthCost
            ) : (
              <Input
                value={state.twelveMonthCost}
                size="small"
                onChange={(event) =>
                  handleChangeCost(event.target.value, periodTypes.twelveMonthsKey)
                }
              />
            )}
          </Col>
          {state.error12 && (
            <div>
              <Text type="danger">{state.error12}</Text>
            </div>
          )}
        </Row>
        <Row>
          <Col span={14}>
            <Button
              size="small"
              onClick={handleButton}
              type="primary"
              disabled={state.error1 || state.error3 || state.error12}
            >
              {state.mode === modes.show ? 'Изменить' : 'Сохранить'}
            </Button>
          </Col>
          <Col span={10}>
            {state.mode === modes.edit && (
              <Button size="small" onClick={handleCancel} type="default">
                Отмена
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Spin>
  )
}

SubscriptionData.propTypes = {
  subscription: PropTypes.object,
  influencerId: PropTypes.string,
}

export default SubscriptionData
