import React, { useContext, useEffect, useState } from 'react'
import { Menu } from 'antd'
import { appConfig } from '../../constants/appConfig'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { TeamOutlined, LogoutOutlined } from '@ant-design/icons'
import { makeUrl } from '../../helpers'
import { auth } from '../../api/auth'
import { ROUTES } from '../../constants/routes'
import { ConfigContext } from '../../contexts/configContext'

const iconList = {
  TeamOutlined: <TeamOutlined />,
  Logout: <LogoutOutlined />,
}

function MenuBar() {
  const history = useHistory()
  const location = useLocation()
  const [activeKey, setActiveKey] = useState(location.pathname)
  const matchUsers = useRouteMatch(ROUTES.USERS)
  const matchInfluencers = useRouteMatch(ROUTES.INFLUENCERS)

  const { setConfig } = useContext(ConfigContext)
  const handleClick = ({ key }) => {
    if (key === 'logout') {
      return handleLogout()
    }
    setActiveKey(key)
    history.replace(
      makeUrl({
        route: key,
      }),
    )
  }
  useEffect(() => {
    if (matchUsers) {
      setActiveKey(ROUTES.USERS)
    }
    if (matchInfluencers) {
      setActiveKey(ROUTES.INFLUENCERS)
    }
  }, [matchUsers, matchInfluencers])

  const handleLogout = () => {
    auth.removeToken()
    setConfig({ isLogged: false })
    history.replace(
      makeUrl({
        route: ROUTES.AUTH,
      }),
    )
  }

  return (
    <div className="menu">
      <Menu mode="inline" theme="light" onClick={handleClick} selectedKeys={[activeKey]}>
        {appConfig.menu.map((item) => (
          <Menu.Item key={item.route} icon={iconList[item.icon]}>
            {item.title}
          </Menu.Item>
        ))}
        <Menu.Item key="logout" icon={iconList.Logout}>
          Выйти
        </Menu.Item>
      </Menu>
    </div>
  )
}

export default MenuBar
