import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'
import { appConfig } from '../../constants/appConfig'

const { Option } = Select

function ColumnPayTypeSearch({ title, onChange, value }) {
  return (
    <>
      {title}
      <br />
      <Select onChange={onChange} allowClear placeholder={title} value={value}>
        {Object.values(appConfig.payment.types).map((item) => (
          <Option key={item} value={item}>
            {appConfig.payment.typeLabels[item]}
          </Option>
        ))}
      </Select>
    </>
  )
}

ColumnPayTypeSearch.defaultProps = {
  title: 'Тип оплаты',
  onChange: () => {},
}

ColumnPayTypeSearch.propTypes = {
  title: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default ColumnPayTypeSearch
