import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Button, Table, Typography, Modal, message } from 'antd'
import { formatDate } from '../../helpers/time'
import { usePayments } from '../../api/users'
import { getFilters, removeFilters, setFilters, simpleReducer } from '../../helpers'
import { appConfig } from '../../constants/appConfig'
import ColumnDateSearch from '../FormItems/ColumnDateSearch'
import ColumnNickSearch from '../FormItems/ColumnNickSearch'
import ColumnPayTypeSearch from '../FormItems/ColumnPayTypeSearch'
import ColumnCardSearch from '../FormItems/ColumnCardSearch'
import ColumnOrderIdSearch from '../FormItems/ColumnOrderIdSearch'
import ColumnPaymentIdSearch from '../FormItems/ColumnPaymentIdSearch'
import { useReturnPayment } from '../../api/administrator'
import moment from 'moment'

const { Title } = Typography

function PayHistory({ followerId }) {
  const initialState = {
    dateFrom: null,
    dateTo: null,
    influencerNick: null,
    paymentType: null,
    orderId: null,
    paymentId: null,
    pan: null,
    filterValues: null,
    page: 0,
    processId: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    const initialFilters = getFilters(appConfig.filters.listNames.payHistory)
    const dateFilters = {
      dateFrom: initialFilters?.dateFrom
        ? moment(initialFilters?.dateFrom, appConfig.dateFormats.apiDateTimeT3)
        : null,
      dateTo: initialFilters?.dateTo
        ? moment(initialFilters?.dateTo, appConfig.dateFormats.apiDateTimeT3)
        : null,
    }
    setState({
      filterValues: {
        ...initialFilters,
        ...dateFilters,
      },
      ...dateFilters,
      influencerNick: initialFilters?.influencerNick || null,
      paymentType: initialFilters?.paymentType || null,
      orderId: initialFilters?.orderId || null,
      paymentId: initialFilters?.paymentId || null,
      pan: initialFilters?.pan || null,
    })
  }, [])

  const { data, isLoading, refetch } = usePayments(
    {
      followerId,
      dateFrom: state.dateFrom?.format(appConfig.dateFormats.apiDateTimeT3),
      dateTo: state.dateTo?.format(appConfig.dateFormats.apiDateTimeAllDay),
      influencerNick: state.influencerNick,
      paymentType: state.paymentType,
      paymentId: state.paymentId,
      orderId: state.orderId,
      pan: state.pan,
      page: state.page,
    },
    {
      enabled: Boolean(followerId),
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )
  const { mutate: returnPayment, isLoading: isLoadingBackPay } = useReturnPayment({
    onSuccess: () => {
      setState({ processId: null })
      refetch()
    },
    onError: () => {
      setState({ processId: null })
      message.error('Не удалось вернуть плтеж')
    },
  })
  const columns = [
    {
      title: (
        <ColumnDateSearch
          onChange={(value) => {
            if (value) {
              const [dateFrom, dateTo] = value
              const dateValues = { dateFrom, dateTo }
              setState({
                ...dateValues,
                filterValues: { ...state.filterValues, ...dateValues },
                page: 0,
              })
              setFilters(appConfig.filters.listNames.payHistory, {
                dateFrom: dateFrom.format(appConfig.dateFormats.apiDateTimeT3),
                dateTo: dateTo.format(appConfig.dateFormats.apiDateTimeAllDay),
              })
            } else {
              const dateValues = { dateFrom: null, dateTo: null }
              setState({
                ...dateValues,
                filterValues: { ...state.filterValues, ...dateValues },
                page: 0,
              })
              setFilters(appConfig.filters.listNames.payHistory, dateValues)
            }
          }}
          value={[state.filterValues?.dateFrom || null, state.filterValues?.dateTo || null]}
        />
      ),
      dataIndex: 'createDate',
      key: 'createDate',
      render: (createDate) => formatDate(createDate),
    },
    {
      title: (
        <ColumnNickSearch
          title="Инфлюенсер"
          onSearch={(influencerNick) => {
            setState({ influencerNick, page: 0 })
            setFilters(appConfig.filters.listNames.payHistory, { influencerNick })
          }}
          onChange={(influencerNick) =>
            setState({
              filterValues: {
                ...state.filterValues,
                influencerNick,
              },
            })
          }
          value={state.filterValues?.influencerNick}
        />
      ),
      dataIndex: 'influencerNickName',
      key: 'influencerNickName',
    },
    {
      title: (
        <ColumnPayTypeSearch
          onChange={(paymentType) => {
            setState({
              paymentType,
              filterValues: {
                ...state.filterValues,
                paymentType,
              },
              page: 0,
            })
            setFilters(appConfig.filters.listNames.payHistory, { paymentType })
          }}
          value={state.filterValues?.paymentType}
        />
      ),
      dataIndex: ['paymentType', 'name'],
      key: 'paymentType',
    },
    {
      title: 'Детали оплаты',
      dataIndex: 'paymentDetail',
      key: 'paymentDetail',
    },
    {
      title: 'Сумма',
      dataIndex: 'paymentSum',
      key: 'paymentSum',
    },
    {
      title: (
        <ColumnCardSearch
          onSearch={(pan) => {
            setState({ pan, page: 0 })
            setFilters(appConfig.filters.listNames.payHistory, { pan })
          }}
          onChange={(pan) =>
            setState({
              filterValues: {
                ...state.filterValues,
                pan,
              },
            })
          }
          value={state.filterValues?.pan}
        />
      ),
      dataIndex: 'bankCardId',
      key: 'bankCardId',
      render: (bankCardId, record) => (
        <>
          {record.bankCardPan} {record.bankCardType?.id} {record.bankCardExpiration}
        </>
      ),
    },
    {
      title: (
        <ColumnOrderIdSearch
          onSearch={(orderId) => {
            setState({ orderId, page: 0 })
            setFilters(appConfig.filters.listNames.payHistory, { orderId })
          }}
          onChange={(orderId) =>
            setState({
              filterValues: {
                ...state.filterValues,
                orderId,
              },
            })
          }
          value={state.filterValues?.orderId}
        />
      ),
      dataIndex: 'orderId',
      key: 'orderId',
    },
    {
      title: (
        <ColumnPaymentIdSearch
          onSearch={(paymentId) => {
            setState({ paymentId, page: 0 })
            setFilters(appConfig.filters.listNames.payHistory, { paymentId })
          }}
          onChange={(paymentId) =>
            setState({
              filterValues: {
                ...state.filterValues,
                paymentId,
              },
            })
          }
          value={state.filterValues?.paymentId}
        />
      ),
      dataIndex: 'paymentId',
      key: 'paymentId',
    },
    {
      title: 'Действия',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (paymentStatus, row) => (
        <>
          {paymentStatus?.id === 'Charged' ? (
            <Button
              loading={isLoadingBackPay && state.processId === row.paymentId}
              size="small"
              onClick={() =>
                Modal.confirm({
                  content: 'Вернуть платеж?',
                  onOk: () => {
                    setState({ processId: row.paymentId })
                    returnPayment({ paymentId: row.paymentId })
                  },
                })
              }
            >
              Вернуть платёж
            </Button>
          ) : (
            'Недоступно'
          )}
        </>
      ),
    },
  ]

  const handleClearFilters = () => {
    setState(initialState)
    removeFilters(appConfig.filters.listNames.payHistory)
  }

  return (
    <div className="expense-list">
      <div className="header-with-button">
        <Title level={4}>История платежей</Title>
        <Button type="primary" onClick={handleClearFilters}>
          Очистить все фильтры
        </Button>
      </div>

      <Table
        scroll={{
          x: 'max-content',
        }}
        className="table-col-header-top"
        rowKey={(record) => record.id}
        loading={isLoading}
        columns={columns}
        dataSource={data?.data?.data || []}
        pagination={{
          onChange: (nextPage) => {
            setState({ page: nextPage - 1 })
          },
          current: state.page + 1,
          pageSize: 10,
          showSizeChanger: false,
          total: data?.data?.nav?.total,
        }}
      />
    </div>
  )
}

PayHistory.propTypes = {
  followerId: PropTypes.string,
}

export default PayHistory
