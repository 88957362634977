import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Typography, message, Row, Col } from 'antd'
import UserAvatar from '../../Components/Profile/UserAvatar'
import SubscribeList from '../../Components/Profile/SubscribeList'
import VipChatList from '../../Components/Profile/VipChatList'
import { useBanUntil, useEditEmail, useEditNickName, useUsers } from '../../api/users'
import { formatDate } from '../../helpers/time'
import PayHistory from '../../Components/Profile/PayHistory'
import EditableDate from '../../Components/Layout/Editable/EditableDate'
import UserAuthData from '../../Components/Profile/UserAuthData'
import { formatSocialId } from '../../helpers'
import UserHardBan from '../../Components/Profile/UserHardBan'

const { Title, Paragraph } = Typography

function UserPage({ userId }) {
  const { data: users } = useUsers(
    {
      followerId: userId,
    },
    {
      enabled: Boolean(userId),
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )

  const { mutate: editNickName } = useEditNickName({
    onSuccess: () => message.success('Ник успешно изменен'),
    onError: () => message.error('Не удалось изменить ник'),
  })

  const { mutate: editEmail } = useEditEmail({
    onSuccess: () => message.success('Email успешно изменен'),
    onError: () => message.error('Не удалось изменить email'),
  })

  const { mutate: banUntil } = useBanUntil({
    onSuccess: () => message.success('Блокировка успешно изменена'),
    onError: () => message.error('Не удалось изменить блокировку'),
  })

  const userData = useMemo(() => (users ? users?.data?.userList[0] : {}), [users])

  return (
    <div className="user">
      <Title level={3}>Подписчик {userData?.fullName}</Title>
      <Row gutter={24}>
        <Col span={8} className="user-card">
          <UserAvatar size={100} src={userData?.follower?.avatarUrl} />
          <div className="user-info">
            <div>
              <b>Email:</b>{' '}
              <Paragraph
                editable={{
                  onChange: (email) => {
                    if (email !== userData?.email) {
                      editEmail({ userId: userData?.id, email, followerId: userId })
                    }
                  },
                }}
              >
                {userData?.email}
              </Paragraph>
            </div>
            <div>
              <b>Ник:</b>{' '}
              <Paragraph
                editable={{
                  onChange: (nickName) => {
                    if (nickName !== userData?.follower?.nickName) {
                      editNickName({ userId: userData?.id, nickName, followerId: userId })
                    }
                  },
                }}
              >
                {userData?.follower?.nickName}
              </Paragraph>
            </div>
            <div>
              <b>Дата создания:</b> <Paragraph>{formatDate(userData?.registrationDate)}</Paragraph>
            </div>
            <div>
              <b>SocialId: </b> {formatSocialId(userData?.follower?.socialId)}
            </div>
          </div>
        </Col>
        <Col span={8}>
          <UserAuthData user={userData} />
        </Col>
        <Col span={8}>
          <div className="user-card col">
            <div className="user-banned">
              <b>Блокировка комментариев до: </b>
              <EditableDate
                date={formatDate(userData?.follower?.bannedUntil)}
                onChange={(date) => banUntil({ followerId: userId, date })}
              />
            </div>
            <UserHardBan user={userData} />
          </div>
        </Col>
      </Row>
      <SubscribeList userId={userId} />
      <VipChatList userId={userId} />
      <PayHistory followerId={userData?.follower?.id} />
    </div>
  )
}

UserPage.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default UserPage
