import { api, queryClient } from './api'
import { useMutation, useQuery } from 'react-query'
import { format } from 'date-fns'
import { getDate } from '../helpers/time'
import { appConfig } from '../constants/appConfig'
import { INFLUENCERS_KEY } from './influencers'
import { USER_INFO_KEY } from './administrator'

export const USERS_KEY = 'usersList'

const filterParams = (params) =>
  Object.entries(params).reduce((result, [key, value]) => {
    if (value !== null) {
      result[key] = value
    }
    return result
  }, {})

const getUsers = ({
  page = 0,
  countPerPage = 10,
  accountCreationDate = null,
  profileType = null,
  nickname = null,
  email = null,
  socialId = null,
  followerId = null,
  exactSearch = false,
} = {}) => {
  const params = {
    page,
    countPerPage,
    accountCreationDate,
    profileType,
    nickname,
    email,
    socialId,
    followerId,
    exactSearch,
  }
  const query = new URLSearchParams(filterParams(params)).toString()
  return api.get({
    path: `/insider/administrator/users${query.length > 0 ? `?${query}` : ''}`,
  })
}

export const useUsers = (query, params) => {
  const queryKey = query.followerId
    ? [USERS_KEY, query.followerId]
    : [USERS_KEY, 'usersList', Object.values(query).join('-')]
  return useQuery(queryKey, () => getUsers(query), params)
}

const getFollowerSubscriptions = (followerId) => {
  return api.get({
    path: `/insider/administrator/subscriptions/${followerId}`,
  })
}

export const useFollowerSubscriptions = (followerId, params) => {
  return useQuery(
    [USERS_KEY, followerId, 'subscriptions'],
    () => getFollowerSubscriptions(followerId),
    params,
  )
}

const getFollowerVipChats = (followerId) => {
  return api.get({
    path: `/insider/administrator/vipchats/${followerId}`,
  })
}

export const useFollowerVipChats = (followerId, params) => {
  return useQuery(
    [USERS_KEY, followerId, 'vipchats'],
    () => getFollowerVipChats(followerId),
    params,
  )
}

const getPayments = ({
  influencerNick,
  followerNick,
  followerId,
  followerEmail,
  paymentType,
  paymentDetail,
  orderId,
  paymentId,
  paymentStatus,
  pan,
  cardType,
  cardExp,
  dateFrom,
  dateTo,
  page = 0,
  perPage = 10,
  showAllPayments = false,
}) => {
  return api.post({
    path: `/insider/administrator/payments`,
    data: filterParams({
      influencerNick,
      followerNick,
      followerId,
      followerEmail,
      paymentType,
      paymentDetail,
      orderId,
      paymentId,
      paymentStatus,
      pan,
      cardType,
      cardExp,
      dateFrom,
      dateTo,
      page,
      perPage,
      ...(showAllPayments && { showAllPayments }),
    }),
  })
}

export const usePayments = (query, params) => {
  return useQuery(
    [USERS_KEY, 'payments', Object.values(query).join('-')],
    (key, pageParam = query?.page) => getPayments({ ...query, page: pageParam }),
    params,
  )
}

const editNickName = ({ userId, nickName, profileType = appConfig.userTypes.follower }) => {
  return api.put({
    path: `/insider/administrator/nickname/${userId}`,
    data: {
      nickName,
      profileType,
    },
  })
}

function updateUser(newData, isFollower = false) {
  return (oldData) => {
    const userList = [
      {
        ...oldData?.data?.userList[0],
        ...(!isFollower && { ...newData }),
        ...(isFollower && { follower: { ...oldData?.data?.userList[0]?.follower, ...newData } }),
      },
    ]
    return {
      ...oldData,
      data: {
        ...oldData.data,
        userList,
      },
    }
  }
}

export const useEditNickName = ({ onSuccess, onError }) => {
  return useMutation(editNickName, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [USERS_KEY, variables.followerId],
        updateUser({ nickName: variables.nickName }, true),
      )
      onSuccess()
    },
    onError: () => onError(),
  })
}

export const useEditNickNameInfluencer = ({ onSuccess, onError }) => {
  return useMutation(editNickName, {
    onSuccess: () => {
      queryClient.refetchQueries(INFLUENCERS_KEY)
      queryClient.refetchQueries(USER_INFO_KEY)
      onSuccess()
    },
    onError: () => onError(),
  })
}

const editEmail = ({ userId, email }) => {
  return api.put({
    path: `/insider/administrator/email/${userId}?email=${email}`,
  })
}

export const useEditEmail = ({ onSuccess, onError }) => {
  return useMutation(editEmail, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [USERS_KEY, variables.followerId],
        updateUser({ email: variables.email }),
      )
      onSuccess()
    },
    onError: () => onError(),
  })
}

const banUntil = ({ followerId, date }) => {
  return api.put({
    path: `/insider/administrator/ban/${followerId}?banUntil=${format(
      getDate(date),
      appConfig.dateFormats.apiDateTimeT,
    )}`,
  })
}

export const useBanUntil = ({ onSuccess, onError }) => {
  return useMutation(banUntil, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        [USERS_KEY, variables.followerId],
        updateUser(
          { bannedUntil: format(getDate(variables.date), appConfig.dateFormats.apiDateTimeT2) },
          true,
        ),
      )
      onSuccess()
    },
    onError: () => onError(),
  })
}

const editSubscriptionType = ({ followerId, influencerId, type }) => {
  return api.put({
    path: `/insider/administrator/subscription-type/${followerId}/${influencerId}?subscriptionType=${type}`,
  })
}

export const useEditSubscriptionType = ({ onSuccess, onError }) => {
  return useMutation(editSubscriptionType, {
    onSuccess: () => onSuccess(),
    onError: () => onError(),
  })
}

const editSubscriptionDate = ({ followerId, influencerId, date }) => {
  return api.put({
    path: `/insider/administrator/subscription-date/${followerId}/${influencerId}?newDate=${date}`,
  })
}

export const useEditSubscriptionDate = ({ onSuccess, onError }) => {
  return useMutation(editSubscriptionDate, {
    onSuccess: () => onSuccess(),
    onError: () => onError(),
  })
}

const editChatDate = ({ followerId, influencerId, date }) => {
  return api.put({
    path: `/insider/administrator/chat-date/${followerId}/${influencerId}?newDate=${date}`,
  })
}

export const useEditChatDate = ({ onSuccess, onError }) => {
  return useMutation(editChatDate, {
    onSuccess: () => onSuccess(),
    onError: () => onError(),
  })
}

const editSubscriptionFlag = ({ followerId, influencerId, isRecurrent }) => {
  return api.put({
    path: `/insider/administrator/subscription-flag/${followerId}/${influencerId}?isRecurrent=${isRecurrent}`,
  })
}

export const useEditSubscriptionFlag = ({ onSuccess, onError }) => {
  return useMutation(editSubscriptionFlag, {
    onSuccess: () => onSuccess(),
    onError: () => onError(),
  })
}
