import React, { useReducer } from 'react'
import 'antd/dist/antd.css'
import Routes from './Routes'
import { ConfigProvider, Layout } from 'antd'
import { ConfigContext } from './contexts/configContext'
import { simpleReducer } from './helpers'
import { auth } from './api/auth'
import MenuBar from './Components/Layout/MenuBar'

import './sass/app.scss'
import locale from 'antd/lib/locale/ru_RU'

const { Header, Sider, Content } = Layout

function App() {
  const initialConfig = {
    isLogged: auth.isLogged(),
  }
  const [config, setConfig] = useReducer(simpleReducer, initialConfig)
  return (
    <ConfigContext.Provider value={{ config, setConfig }}>
      <ConfigProvider locale={locale}>
        <Layout className="layout">
          {config.isLogged && (
            <Sider className="sider">
              <MenuBar />
            </Sider>
          )}
          <Layout>
            {config.isLogged && (
              <Header className="header">
                <h2 className="header-text">Административная панель Insider</h2>
              </Header>
            )}
            <Content className="content">
              <Routes />
            </Content>
          </Layout>
        </Layout>
      </ConfigProvider>
    </ConfigContext.Provider>
  )
}

export default App
