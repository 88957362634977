import { nbsp, rub } from '../constants/typography'
import { generatePath } from 'react-router-dom'
import { isWebpSupported } from 'react-image-webp/dist/utils'
import queryString from 'query-string'
import { chunk, toArray } from 'lodash-es'
import { appConfig } from '../constants/appConfig'

export function simpleReducer(state, value) {
  return {
    ...state,
    ...value,
  }
}

export const getWithSinglePrefix = (value, prefix) =>
  value ? `${prefix}${value?.replace(prefix, '')}` : value

export const clearProtocolHost = (value) => {
  let result
  try {
    const urlData = new URL(value)
    result = value
      ?.replace(urlData.origin, '')
      .replace(/^\//, '')
      .replace(urlData.host, '')
      .replace(/^\//, '')
  } catch (e) {
    result = value
  }
  return result
}

/**
 * @param {number} number
 * @param {array} [words = ["яблоко", "яблока", "яблок"]]
 * @return {string}
 */
export const numberDeclension = (number, words) =>
  words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? number % 10 : 5]
  ]

export const formatPrice = (price) => {
  const digits = toArray(price?.toString())?.reverse()
  if (digits?.length > 0) {
    let decs = chunk(digits, 3)
    decs = decs.map((item) => item.reverse().join('')).reverse()
    return `${decs.join(' ')}${nbsp}${rub}`
  }
  return price
}

/**
 * Group by prop
 * @param list []
 * @param prop string
 * @returns {*}
 */
/* eslint-disable indent */
export function groupBy(list, prop, defaultGroupName = 'none') {
  return Array.isArray(list)
    ? list.reduce((groups, item) => {
        const groupName = item[prop] || defaultGroupName
        const group = groups[groupName] || []
        group.push(item)
        groups[groupName] = group
        return groups
      }, {})
    : {}
}
/* eslint-enable indent */

export const isLocationEqual = (locationStr, routes) => {
  return locationStr && routes?.includes(locationStr)
}

export const convertArrayToObject = (list, keyName) => {
  const object = {}
  list?.forEach((item) => {
    if (item && item[keyName]) {
      object[item[keyName]] = { ...item }
    }
  })
  return object
}

export const makeUrl = ({ route, routeParams = {}, params = {}, isAbsolute = false }) => {
  const stringParams = new URLSearchParams(params).toString()
  const host = isAbsolute ? window.location.origin : ''
  return host + generatePath(route, routeParams) + (stringParams ? `?${stringParams}` : '')
}

export const makeSecureLink = ({ path, params, isImage = true }) => {
  let ext = ''
  if (isImage && isWebpSupported) {
    params.o = `${path.split('/').pop()}`
    ext = '.webp'
  }
  return `${path}${ext}?${queryString.stringify(params)}`
}

export const isWrongAnyGetParam = (params) => {
  return Object.values(params || {}).some(
    (value) =>
      value === undefined ||
      value === null ||
      value === 'undefined' ||
      value === 'null' ||
      value === '',
  )
}

export function upperFirst(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

export const setFilters = (listName, filters) => {
  const storageFilters = JSON.parse(localStorage.getItem(appConfig.filters.storageName))
  const listFilters = storageFilters?.[listName]
  const result = {
    ...storageFilters,
    [listName]: {
      ...listFilters,
      ...filters,
    },
  }
  localStorage.setItem(appConfig.filters.storageName, JSON.stringify(result))
}

export const getFilters = (listName) => {
  const filters = JSON.parse(localStorage.getItem(appConfig.filters.storageName))
  return filters?.[listName]
}

export const removeFilters = (listName) => {
  const storageFilters = JSON.parse(localStorage.getItem(appConfig.filters.storageName))
  delete storageFilters?.[listName]
  localStorage.setItem(appConfig.filters.storageName, JSON.stringify(storageFilters))
}

export const formatSocialId = (socialId) => socialId

export function arrayUniqValues(list = []) {
  return list.filter((item, pos) => list.indexOf(item) === pos)
}

/**
 * Парсит входящую строку JSON для вывода данных. Парсится только первый уровень вложенности, остальное остаётся строкой
 * @param JSONString
 * @returns object
 */
export const parseJSONFirstLevel = (JSONString = '[]') =>
  JSON.parse(JSONString, (key, value) =>
    key && (typeof value === 'object' || typeof value === 'boolean' || Array.isArray(value))
      ? JSON.stringify(value)
      : value,
  )
