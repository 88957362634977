import React, { useEffect, useContext, useCallback } from 'react'
import { Spin, Form, Input, Button, message } from 'antd'
import { useHistory } from 'react-router-dom'
import { appConfig } from '../../constants/appConfig'
import { ROUTES } from '../../constants/routes'
import { ConfigContext } from '../../contexts/configContext'
import { useMutateLogin, useMutateSocial, auth, TEMP_TOKEN } from '../../api/auth'
import Yandex from '../../Components/Auth/Yandex'
import Google from '../../Components/Auth/Google'
import LogoSpinner from '../../Components/Layout/LogoSpinner'

function Auth() {
  const [form] = Form.useForm()
  const history = useHistory()
  const { config, setConfig } = useContext(ConfigContext)

  const {
    mutate: mutateLogin,
    isSuccess: isSuccessLogin,
    isError: isErrorLogin,
    isLoading: isLoadingLogin,
    data: dataLogin,
  } = useMutateLogin()

  const {
    mutate: mutateSocial,
    isSuccess: isSuccessSocial,
    isError: isErrorSocial,
    isLoading: isLoadingSocial,
    isIdle: isIdleSocial,
    data: dataSocial,
  } = useMutateSocial()

  useEffect(() => {
    if (config.isLogged) {
      history.replace(ROUTES.ROOT)
    }
  }, [config.isLogged, history])

  const handleConfirmAuth = useCallback(
    ({ data }) => {
      console.log('Social OK')
      if (data?.user?.role === appConfig.userTypes.administrator) {
        setConfig({ isLogged: true })
        auth.setToken(TEMP_TOKEN)
        history.replace(ROUTES.ROOT)
      } else {
        message.error('Не удалось выполнить авторизацию')
      }
    },
    [setConfig, history],
  )

  useEffect(() => {
    if (isSuccessSocial) {
      handleConfirmAuth(dataSocial)
    } else if (isErrorSocial) {
      message.error('Ошибка авторизации')
    }
  }, [isSuccessSocial, isErrorSocial, handleConfirmAuth])

  useEffect(() => {
    if (isSuccessLogin) {
      handleConfirmAuth(dataLogin)
    } else if (isErrorLogin) {
      message.error('Ошибка авторизации')
    }
  }, [isSuccessLogin, isErrorLogin, handleConfirmAuth])

  const handleLogin = (values) => {
    mutateLogin({ login: values.login, password: values.password })
  }

  const tmpHide = true

  return (
    <div className="auth">
      <h2>Административная панель</h2>
      <Spin spinning={isLoadingLogin || isLoadingSocial} size="large">
        <div className="auth-header">
          <LogoSpinner isLoading withLogo size={40} />
        </div>
        <div className="auth-content">
          <div className="auth-line">
            <Yandex
              authHandler={(data) => {
                if (isIdleSocial) {
                  mutateSocial(data)
                }
              }}
            />
            <Google
              authHandler={(data) => {
                if (isIdleSocial) {
                  mutateSocial(data)
                }
              }}
            />
          </div>
        </div>

        {tmpHide ? (
          <></>
        ) : (
          <Form form={form} onFinish={handleLogin} className="auth-form">
            <Form.Item
              label="Логин"
              name="login"
              labelCol={{ span: 6 }}
              labelAlign="left"
              rules={[{ required: true, message: 'Введите логин' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Пароль"
              name="password"
              labelCol={{ span: 6 }}
              labelAlign="left"
              rules={[{ required: true, message: 'Введите пароль' }]}
            >
              <Input.Password />
            </Form.Item>
            <div className="button-block">
              <Button htmlType="submit">Войти</Button>
            </div>
          </Form>
        )}
      </Spin>
    </div>
  )
}

export default Auth
