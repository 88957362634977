import { isWebpSupported } from 'react-image-webp/dist/utils'
import queryString from 'query-string'

export function useWebpLinks({ path, params = {}, isOriginal = false }) {
  if (!path) {
    return { link: undefined }
  }
  let ext = ''
  if (isWebpSupported) {
    params.o = `${path.split('/').pop()}`
    ext = isOriginal ? '' : '.webp'
  }
  return {
    link: `${path}${ext}?${queryString.stringify(params)}`,
  }
}
