import React, { useEffect, useReducer } from 'react'
import { Input, Typography, Row, Col, List } from 'antd'
import { arrayUniqValues, simpleReducer } from '../../helpers'
import PropTypes from 'prop-types'

const { Text } = Typography

const needCountDigits = 6

function UserSocial({ onSelect, socialString, onChangeString }) {
  const initialState = {
    error: null,
    resultList: [],
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    if (socialString) {
      makeSocialList(socialString.trim())
    } else {
      setState(initialState)
    }
  }, [socialString])

  const handleChange = (e) => {
    const value = e.target.value
    onChangeString(value)
  }

  const makeSocialList = (value) => {
    const digits = value?.split(' ')
    if (digits.length < needCountDigits) {
      setState({ error: `Должно быть не меньше ${needCountDigits} чисел`, resultList: [] })
      return
    }
    setState({ error: null, resultList: [] })
    const result = []
    const digitsDiff = digits.length - needCountDigits
    for (let i = 0; i <= digitsDiff; i++) {
      const start = i
      const end = start + needCountDigits
      const res = calcSocial(digits.slice(start, end))
      if (res >= 100000) {
        result.push(res)
      }
    }
    setState({ resultList: arrayUniqValues(result) })
  }

  const calcSocial = (digits) => {
    let sum = 0
    let mul = 1
    for (let i = digits.length; i > 1; i--) {
      let diff = parseInt(digits[i - 1]) - parseInt(digits[i - 2]) - 1
      if (diff < 0) {
        diff += 16
      }
      sum += diff * mul
      mul *= 15
    }
    return sum
  }

  const handleChangeList = (value) => {
    onSelect(value)
  }

  return (
    <div className="userSocial">
      {/*{'0 3 5 11 6 1'} for test*/}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <span className="title">Код доступа</span>
          <Input onChange={handleChange} allowClear value={socialString} />
        </Col>
        <Col span={12}>
          <span className="title">Результат</span>
          {state.resultList.length > 0 && (
            <List
              size="small"
              bordered
              dataSource={state.resultList}
              renderItem={(item) => (
                <List.Item onClick={() => handleChangeList(item)}>{item}</List.Item>
              )}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Text type="danger">{state.error}</Text>
        </Col>
      </Row>
    </div>
  )
}

UserSocial.defaultProps = {
  onSelect: () => {},
  onChangeString: () => {},
  socialString: '',
}

UserSocial.propTypes = {
  onSelect: PropTypes.func,
  onChangeString: PropTypes.func,
  socialString: PropTypes.string,
}

export default UserSocial
