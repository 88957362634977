import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import reportTemplate from '../../data/reports/report-agent.docx'
import { Button, DatePicker, message } from 'antd'
import Docxtemplater from 'docxtemplater'
import PizZip from 'pizzip'
import PizZipUtils from 'pizzip/utils/index.js'
import { saveAs } from 'file-saver'
import { simpleReducer, upperFirst } from '../../helpers'
import { useMutateAgentPaymentStats } from '../../api/administrator'
import moment from 'moment'
import { appConfig } from '../../constants/appConfig'
import StringRub from 'rubles'

const { RangePicker } = DatePicker
const paymentTypes = appConfig.payment.types
const dateFormats = appConfig.dateFormats
const emptyVal = '-'
const emptyTemplate = '________'

function ReportAgent({ influencer }) {
  const initialState = {
    start: null,
    end: null,
    statsData: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    mutate: mutatePaymentStats,
    data: paymentStatsData,
    isSuccess: isSuccessPaymentStats,
    isError: isErrorPaymentStats,
  } = useMutateAgentPaymentStats()

  useEffect(() => {
    if (isSuccessPaymentStats && paymentStatsData) {
      setState({ statsData: makeStatsData(paymentStatsData?.data?.data) })
      generateDocument(makeStatsData(paymentStatsData?.data))
    } else if (isErrorPaymentStats) {
      message.error('Ошибка загрузки данных агента')
    }
  }, [paymentStatsData, isSuccessPaymentStats, isErrorPaymentStats])

  const getFullName = (data) => {
    let list = []
    if (data?.influencerLastName) {
      list.push(upperFirst(data?.influencerLastName))
    }
    if (data?.influencerFirstName) {
      list.push(upperFirst(data?.influencerFirstName))
    }
    if (data?.influencerPatronymicName) {
      list.push(upperFirst(data?.influencerPatronymicName))
    }
    if (list.join(' ').trim()) {
      return list.join(' ')
    }
    return emptyTemplate
  }

  const getShortName = (data) => {
    let list = []
    if (data?.influencerLastName) {
      list.push(upperFirst(data?.influencerLastName))
    }
    if (data?.influencerFirstName?.[0]) {
      list.push(`${upperFirst(data?.influencerFirstName?.[0])}.`)
    }
    if (data?.influencerPatronymicName?.[0]) {
      list.push(`${upperFirst(data?.influencerPatronymicName?.[0])}.`)
    }
    if (list.join(' ').trim()) {
      return list.join(' ')
    }
    return emptyTemplate
  }

  const getTotalSum = (statsData) => {
    const subscribeData = statsData?.[paymentTypes.subscribe]
    const vipChatData = statsData?.[paymentTypes.vipChat]
    const donateData = statsData?.[paymentTypes.donate]
    let res = subscribeData?.sum ? Math.floor(subscribeData?.sum) : 0
    res += vipChatData?.sum ? Math.floor(vipChatData?.sum) : 0
    res += donateData?.sum ? Math.floor(donateData?.sum) : 0
    return res
  }

  const getProfitSum = (statsData) => {
    const total = getTotalSum(statsData)
    const rent = (statsData?.influencerCommission || 0) / 100
    const profit = Math.floor(total - total * rent)
    return profit
  }

  const getRentSum = (statsData) => {
    return Math.floor(getTotalSum(statsData) - getProfitSum(statsData))
  }

  const getSumString = (sum) => {
    if (sum > 0) {
      const str = StringRub.rubles(sum)
      return `(${str?.replace(' руб', ') руб')}`
    }
    return `(ноль) рублей 00 копеек`
  }

  const makeStatsData = (data) => {
    const object = { ...data }
    data?.data?.forEach((item) => {
      if (item?.paymentType?.id) {
        object[item?.paymentType?.id] = { ...item }
      }
    })
    return object
  }

  const makeTemplateData = (statsData) => {
    const subscribeData = statsData?.[paymentTypes.subscribe]
    const vipChatData = statsData?.[paymentTypes.vipChat]
    const donateData = statsData?.[paymentTypes.donate]
    return {
      nowDate: moment().format(dateFormats.shortDateDoc),
      startDate: moment(state.start, dateFormats.shortDate).format(dateFormats.shortDateDoc),
      endDate: moment(state.end, dateFormats.shortDate).format(dateFormats.shortDateDoc),
      influencerAgreement: statsData?.influencerAgreement || emptyTemplate,
      influencerAgreementDate: statsData?.influencerAgreementDate
        ? moment(statsData?.influencerAgreementDate, dateFormats.apiDateTime).format(
            dateFormats.shortDateDoc,
          )
        : emptyTemplate,
      ipFullName: getFullName(statsData),
      ipShortName: getShortName(statsData),
      subscribeCount: subscribeData?.count >= 0 ? subscribeData?.count : emptyVal,
      subscribeAverageCost:
        subscribeData?.average >= 0 ? Math.floor(subscribeData?.average) : emptyVal,
      subscribeSoldTotal: subscribeData?.sum >= 0 ? Math.floor(subscribeData?.sum) : emptyVal,
      vipChatCount: vipChatData?.count >= 0 ? vipChatData?.count : emptyVal,
      vipChatAverageCost: vipChatData?.average >= 0 ? Math.floor(vipChatData?.average) : emptyVal,
      vipChatSoldTotal: vipChatData?.sum >= 0 ? Math.floor(vipChatData?.sum) : emptyVal,
      donateCount: donateData?.count >= 0 ? donateData?.count : emptyVal,
      donateAverageCost: donateData?.average >= 0 ? Math.floor(donateData?.average) : emptyVal,
      donateSoldTotal: donateData?.sum >= 0 ? Math.floor(donateData?.sum) : emptyVal,
      totalCount:
        (subscribeData?.count || 0) + (vipChatData?.count || 0) + (donateData?.count || 0),
      totalAverageCost: emptyVal,
      totalSum: getTotalSum(statsData),
      totalSumString: getSumString(getTotalSum(statsData)),
      profitSum: getProfitSum(statsData),
      profitSumString: getSumString(getProfitSum(statsData)),
      rentSum: getRentSum(statsData),
      rentSumString: getSumString(getRentSum(statsData)),
    }
  }

  const handleGenerate = () => {
    const dateFrom = moment(state.start, dateFormats.shortDate).format(
      appConfig.dateFormats.apiDateTimeT3,
    )
    const dateTo = moment(state.end, dateFormats.shortDate).format(
      appConfig.dateFormats.apiDateTimeAllDay,
    )
    mutatePaymentStats({
      influencerId: influencer?.id,
      dateFrom,
      dateTo,
    })
  }

  const loadFile = (url, callback) => {
    PizZipUtils.getBinaryContent(url, callback)
  }

  const replaceErrors = (key, value) => {
    if (value instanceof Error) {
      return Object.getOwnPropertyNames(value).reduce(function (error, key) {
        error[key] = value[key]
        return error
      }, {})
    }
    return value
  }

  const generateDocument = (data) => {
    loadFile(reportTemplate, function (error, content) {
      if (error) {
        throw error
      }
      const zip = new PizZip(content)
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      })
      doc.setData(makeTemplateData(data))
      try {
        doc.render()
      } catch (error) {
        console.log(JSON.stringify({ error: error }, replaceErrors))

        if (error.properties && error.properties.errors instanceof Array) {
          const errorMessages = error.properties.errors
            .map(function (error) {
              return error.properties.explanation
            })
            .join('\n')
          console.log('errorMessages', errorMessages)
        }
        throw error
      }
      const out = doc.getZip().generate({
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      })
      saveAs(out, `report-agent-${influencer.nickName}.docx`)
    })
  }

  const handleRangePicker = (param1, param2) => {
    console.log('param1', param1)
    console.log('param2', param2)
    setState({
      start: param2[0],
      end: param2[1],
    })
  }

  return (
    <div className="reports-agent">
      <RangePicker onChange={handleRangePicker} format={dateFormats.shortDate} />
      <Button
        type="primary"
        onClick={handleGenerate}
        className="button"
        disabled={!state.start || !state.end || !influencer?.id}
      >
        Сформировать отчет
      </Button>
    </div>
  )
}

ReportAgent.propTypes = {
  influencer: PropTypes.object,
}

export default ReportAgent
