import React from 'react'
import PropTypes from 'prop-types'
import { message, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import { makeUrl } from '../../helpers'
import { ROUTES } from '../../constants/routes'
import UserAvatar from './UserAvatar'
import { formatDate, getDate, isDateExpired } from '../../helpers/time'
import {
  useEditSubscriptionDate,
  useEditSubscriptionFlag,
  useEditSubscriptionType,
  useFollowerSubscriptions,
} from '../../api/users'
import EditableTable from '../Layout/Editable/EditableTable'
import { appConfig } from '../../constants/appConfig'
import { format } from 'date-fns'

const { Title } = Typography

function SubscribeList({ userId }) {
  const history = useHistory()

  const { data, isLoading } = useFollowerSubscriptions(userId, {
    enabled: Boolean(userId),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const { mutate: editSubscriptionType } = useEditSubscriptionType({
    onSuccess: () => {},
    onError: () => message.error('Не удалось изменить тип подписки'),
  })

  const { mutate: editSubscriptionDate } = useEditSubscriptionDate({
    onSuccess: () => {},
    onError: () =>
      message.error(
        'Не удалось изменить срок подписки. По данной подписке была операция оплаты за последние 5 дней',
      ),
  })

  const { mutate: editSubscriptionFlag } = useEditSubscriptionFlag({
    onSuccess: () => {},
    onError: () => message.error('Не удалось изменить флаг рекуррента'),
  })

  const goToInfluencerPage = (userId) => {
    history.push(
      makeUrl({
        route: ROUTES.INFLUENCERS,
        routeParams: { userId },
      }),
    )
  }

  const columns = [
    {
      title: 'Ник',
      dataIndex: 'influencerNickname',
      key: 'influencerNickname',
      render: (nickname, record) => {
        return (
          <div onClick={() => goToInfluencerPage(record.influencerUserId)}>
            <UserAvatar src={record.avatarUrl} size={25} /> {nickname}
          </div>
        )
      },
    },
    {
      title: 'Тип подписки',
      dataIndex: 'subscriptionType',
      key: 'subscriptionType',
      editable: true,
      editableField: {
        type: 'Select',
        options: Object.values(appConfig.subscribe.types),
      },
    },
    {
      title: 'Срок действия подписки',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      editable: true,
      editableField: {
        type: 'DatePicker',
      },
      render: (expirationDate) => (
        <div className={isDateExpired(expirationDate) ? 'text-error' : 'text-success'}>
          {formatDate(expirationDate)}
        </div>
      ),
    },
    {
      title: 'Рекуррент',
      dataIndex: 'isRecurrent',
      key: 'isRecurrent',
      editable: true,
      editableField: {
        type: 'Switch',
      },
      render: (isRecurrent) => (isRecurrent ? 'Да' : 'Нет'),
    },
    {
      title: 'Карта',
      dataIndex: 'cardId',
      key: 'cardId',
      render: (cardId, record) => (
        <>
          {record.pan} {record.cardType} {record.cardExpirationDate}
        </>
      ),
    },
    {
      title: 'Месяцы подписки',
      dataIndex: 'subscribedMonths',
      key: 'subscribedMonths',
    },
    {
      title: 'Рейтинг',
      dataIndex: 'userScore',
      key: 'userScore',
    },
    {
      title: 'Топ',
      dataIndex: 'userScoreTop',
      key: 'userScoreTop',
    },
  ]

  const handleSave = (newData, oldData) => {
    if (newData?.subscriptionType && newData?.subscriptionType !== oldData.subscriptionType) {
      editSubscriptionType({
        followerId: userId,
        influencerId: oldData.influencerId,
        type: newData.subscriptionType,
      })
    }
    if (newData?.isRecurrent !== oldData.isRecurrent) {
      editSubscriptionFlag({
        followerId: userId,
        influencerId: oldData.influencerId,
        isRecurrent: newData.isRecurrent,
      })
    }
    if (
      newData?.expirationDate &&
      getDate(newData?.expirationDate).toString() !== getDate(oldData.expirationDate).toString()
    ) {
      editSubscriptionDate({
        followerId: userId,
        influencerId: oldData.influencerId,
        date: format(getDate(newData.expirationDate), appConfig.dateFormats.apiDateTimeT),
      })
    }
  }

  return (
    <div className="subscribe-list">
      <Title level={4}>Подписки</Title>
      <EditableTable
        rowKey="subscriptionId"
        pagination={false}
        loading={isLoading}
        originColumns={columns}
        originData={data?.data || []}
        scroll={{ x: 'max-content' }}
        onSave={handleSave}
      />
    </div>
  )
}

SubscribeList.propTypes = {
  userId: PropTypes.string,
}

export default SubscribeList
