import React, { useEffect, useReducer, useRef } from 'react'
import PropTypes from 'prop-types'
import { Typography, Spin, Collapse, Row, Col, Button, message, Modal } from 'antd'
import UserAvatar from '../../Components/Profile/UserAvatar'
import { useUserInformation } from '../../api/administrator'
import ReportAgent from '../../Components/Reports/ReportAgent'
import AgentData from '../../Components/Profile/AgentData'
import InfluencerStatus from '../../Components/Profile/InfluencerStatus'
import IncomeList from '../../Components/Profile/IncomeList'
import SubscriptionData from '../../Components/Profile/SubscriptionData'
import VipChatData from '../../Components/Profile/VipChatData'
import EarningsList from '../../Components/Profile/EarningsList'
import IncomeForm from '../../Components/Profile/IncomeForm'
import { simpleReducer } from '../../helpers'
import { useEditNickNameInfluencer } from '../../api/users'
import { appConfig } from '../../constants/appConfig'

const { Text, Paragraph } = Typography
const { Panel } = Collapse
const panelList = {
  info: 'info',
  cost: 'cost',
  links: 'links',
  earnings: 'earnings',
  incomes: 'incomes',
  reports: 'reports',
}
const collapseDefaultPanels = Object.values(panelList)

function UserPage({ userId }) {
  const earningsListRef = useRef(null)
  const initialState = {
    showIncomeForm: false,
    showEarningsReset: collapseDefaultPanels.includes(panelList.earnings),
    userInfo: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    isLoading: isLoadingInfo,
    data: userInfo,
    isSuccess: isSuccessInfo,
    isError: isErrorInfo,
  } = useUserInformation({ userId }, { enabled: Boolean(userId) })

  useEffect(() => {
    if (isSuccessInfo && userInfo) {
      setState({ userInfo: userInfo.data })
    } else if (isErrorInfo) {
      message.error('Ошибка загрузки данных инфлюенсера')
    }
  }, [isSuccessInfo, isErrorInfo, userInfo])

  const { mutate: editNickName } = useEditNickNameInfluencer({
    onSuccess: () => message.success('Ник успешно изменен'),
    onError: () => message.error('Не удалось изменить ник'),
  })

  const confirmNickModal = (nickName) => {
    Modal.confirm({
      title: 'Изменить никнэйм?',
      onOk: () => {
        editNickName({
          userId: state.userInfo?.id,
          nickName,
          profileType: appConfig.userTypes.influencer,
        })
      },
    })
  }

  const getIncomeHeader = () => {
    return (
      <>
        Выплаты{' '}
        <Button
          className="addIncomeButton"
          size="small"
          onClick={(event) => {
            event.stopPropagation()
            setState({ showIncomeForm: !state.showIncomeForm })
          }}
        >
          {state.showIncomeForm ? 'Отменить' : 'Добавить'}
        </Button>
      </>
    )
  }

  const handleSaveIncome = () => {
    setState({ showIncomeForm: false })
  }

  const getEarningsHeader = () => {
    return (
      <>
        Доходы{' '}
        {state.showEarningsReset && (
          <Button
            className="addIncomeButton"
            type="primary"
            size="small"
            onClick={(event) => {
              event.stopPropagation()
              earningsListRef?.current?.clearFilters()
            }}
          >
            Очистить все фильтры
          </Button>
        )}
      </>
    )
  }

  const handleCollapseChange = (activeList) => {
    setState({ showEarningsReset: activeList.includes(panelList.earnings) })
  }

  return (
    <div className="user">
      <Spin spinning={isLoadingInfo}>
        <div className="user-card">
          <div className="user-card-avatar">
            <UserAvatar size={100} src={state.userInfo?.influencer?.avatarUrl} />
          </div>
          <div className="user-card-name">
            <Paragraph
              className="paragraphTitle"
              editable={{
                onChange: (nickName) => {
                  if (nickName !== state.userInfo?.influencer?.nickName) {
                    confirmNickModal(nickName)
                  }
                },
              }}
            >
              {state.userInfo?.influencer?.nickName}
            </Paragraph>
            <Text>Подписчиков: {state.userInfo?.subscribersCount || 0}</Text>
            <Text>Email: {state.userInfo?.email}</Text>
          </div>
          <AgentData influencerId={state.userInfo?.influencer?.id} />
          <InfluencerStatus data={state.userInfo} />
        </div>
        <Collapse defaultActiveKey={collapseDefaultPanels} onChange={handleCollapseChange}>
          <Panel header="Информация" key={panelList.info} className="card">
            <p>
              <b>ФИО:</b> {state.userInfo?.influencer?.influencerFullName}
            </p>
            <p>
              <b>Описание:</b> {state.userInfo?.influencer?.influencerLongDescription}
            </p>
            <p>
              <b>Короткое описание:</b> {state.userInfo?.influencer?.influencerShortDescription}
            </p>
            <p>
              <b>Заголовок:</b> {state.userInfo?.influencer?.influencerTitle}
            </p>
            <p>
              <b>Постов:</b> {state.userInfo?.influencer?.blogPosts?.length || 0}
            </p>
          </Panel>
          <Panel header="Стоимость" key={panelList.cost} className="card">
            <Row gutter={16}>
              <Col span={6}>
                <SubscriptionData
                  subscription={state.userInfo?.influencer?.subscription}
                  influencerId={state.userInfo?.influencer?.id}
                />
              </Col>
              <Col span={6}>
                <VipChatData
                  chatCost={state.userInfo?.influencer?.chatCost}
                  influencerId={state.userInfo?.influencer?.id}
                />
              </Col>
            </Row>
          </Panel>
          <Panel header="Ссылки на соцсети" key={panelList.links} className="card">
            <p>
              <b>instagram:</b>{' '}
              {state.userInfo?.influencer?.instagramLink ? (
                <a
                  href={state.userInfo?.influencer?.instagramLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {state.userInfo?.influencer?.instagramLink}
                </a>
              ) : (
                '-'
              )}
            </p>
            <p>
              <b>tiktok:</b>{' '}
              {state.userInfo?.influencer?.tikTokLink ? (
                <a href={state.userInfo?.influencer?.tikTokLink} target="_blank" rel="noreferrer">
                  {state.userInfo?.influencer?.tikTokLink}
                </a>
              ) : (
                '-'
              )}
            </p>
            <p>
              <b>youtube:</b>{' '}
              {state.userInfo?.influencer?.youTubeLink ? (
                <a href={state.userInfo?.influencer?.youTubeLink} target="_blank" rel="noreferrer">
                  {state.userInfo?.influencer?.youTubeLink}
                </a>
              ) : (
                '-'
              )}
            </p>
            <p>
              <b>twitch:</b>{' '}
              {state.userInfo?.influencer?.twitchLink ? (
                <a href={state.userInfo?.influencer?.twitchLink} target="_blank" rel="noreferrer">
                  {state.userInfo?.influencer?.twitchLink}
                </a>
              ) : (
                '-'
              )}
            </p>
          </Panel>
          <Panel header={getEarningsHeader()} key={panelList.earnings} className="card">
            <EarningsList nick={state.userInfo?.influencer?.nickName} ref={earningsListRef} />
          </Panel>
          <Panel header={getIncomeHeader()} key={panelList.incomes} className="card">
            {state.showIncomeForm && (
              <IncomeForm influencerId={state.userInfo?.influencer?.id} onSave={handleSaveIncome} />
            )}
            <IncomeList influencerId={state.userInfo?.influencer?.id} />
          </Panel>
          <Panel header="Отчеты" key={panelList.reports} className="card">
            <b>Отчет агента</b>
            <ReportAgent influencer={state.userInfo?.influencer} />
          </Panel>
        </Collapse>
      </Spin>
    </div>
  )
}

UserPage.propTypes = {
  userId: PropTypes.string.isRequired,
}

export default UserPage
