import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Button, Input, Row, Col, Typography, message, Spin } from 'antd'
import { simpleReducer } from '../../helpers'
import { useMutateChangeChatPrice } from '../../api/administrator'

const modes = {
  edit: 'edit',
  show: 'show',
}

const { Text } = Typography
const ERROR_COST = 'Стоимость премиум-чата должна быть больше 0 '

function VipChatData({ chatCost, influencerId }) {
  const initialState = {
    mode: modes.show,
    chatCost,
    error: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    mutate: changeChatPrice,
    isSuccess: isSuccessPrice,
    isError: isErrorPrice,
    isLoading: isLoadingPrice,
  } = useMutateChangeChatPrice()

  useEffect(() => {
    if (isSuccessPrice) {
      setState({ mode: modes.show })
    } else if (isErrorPrice) {
      message.error('Ошибка сохранения данных')
    }
  }, [isSuccessPrice, isErrorPrice])

  useEffect(() => {
    setState({ chatCost })
  }, [chatCost])

  const handleChangeCost = (value) => {
    const digits = value?.replace(/([^\d])/, '')
    const error = isValidCost(digits) ? null : ERROR_COST
    setState({ chatCost: value, error })
  }

  const handleButton = () => {
    if (state.mode === modes.show) {
      setState({ mode: modes.edit })
    } else {
      changeChatPrice({ influencerId, data: { newCost: state.chatCost } })
    }
  }

  const handleCancel = () => {
    setState(initialState)
  }

  const isValidCost = (value) => value > 0

  return (
    <Spin spinning={isLoadingPrice}>
      <div className="subscription">
        <Row className="subscription-row">
          <Col span={14}>
            <b>Премиум чат:</b>
          </Col>
          <Col span={10}>
            {state.mode === modes.show ? (
              state.chatCost
            ) : (
              <Input
                value={state.chatCost}
                size="small"
                onChange={(event) => handleChangeCost(event.target.value)}
              />
            )}
          </Col>
          {state.error && (
            <div>
              <Text type="danger">{state.error}</Text>
            </div>
          )}
        </Row>
        <Row>
          <Col span={14}>
            <Button size="small" onClick={handleButton} type="primary" disabled={state.error}>
              {state.mode === modes.show ? 'Изменить' : 'Сохранить'}
            </Button>
          </Col>
          <Col span={10}>
            {state.mode === modes.edit && (
              <Button size="small" onClick={handleCancel} type="default">
                Отмена
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </Spin>
  )
}

VipChatData.propTypes = {
  chatCost: PropTypes.number,
  influencerId: PropTypes.string,
}

export default VipChatData
