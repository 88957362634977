import { useMutation } from 'react-query'
import { api } from './api'

export const TEMP_TOKEN = '123'

const fetchLogin = ({ login, password }) => {
  const params = {
    email: login,
    password,
  }
  return api.post({
    path: '/insider/auth/login/email',
    data: params,
  })
}

const fetchSocialLogin = ({ token, socialId, provider, redirectUrl }) => {
  return api.post({
    path: '/insider/auth/login/social',
    data: {
      token,
      socialId,
      provider,
      redirectUrl,
    },
  })
}

export function useMutateSocial() {
  return useMutation(fetchSocialLogin)
}

export function useMutateLogin() {
  return useMutation(fetchLogin, {
    onSuccess: (data) => {
      console.log('data', data)
    },
  })
}

const getToken = () => localStorage.getItem('token') || ''

const setToken = (token) => localStorage.setItem('token', token)

const removeToken = () => localStorage.removeItem('token')

// const isLogged = () => !!getToken()
const isLogged = () => getToken() === TEMP_TOKEN

export const auth = {
  getToken,
  setToken,
  removeToken,
  isLogged,
}
