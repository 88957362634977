import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'

const { Search } = Input

function ColumnPaymentIdSearch({ title, onSearch, onChange, value }) {
  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <>
      {title}
      <br />
      <Search
        allowClear
        placeholder={title}
        onSearch={onSearch}
        value={value}
        onChange={handleChange}
      />
    </>
  )
}

ColumnPaymentIdSearch.defaultProps = {
  title: 'Payment ID',
  onSearch: () => {},
  onChange: () => {},
}

ColumnPaymentIdSearch.propTypes = {
  title: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default ColumnPaymentIdSearch
