import { ROUTES } from './routes'

const userTypes = {
  administrator: 'Administrator',
  influencer: 'Influencer',
  follower: 'Follower',
}

const paymentTypes = {
  donate: 'Donate',
  subscribe: 'Subscription',
  vipChat: 'VipChat',
}

const periodTypes = {
  oneMonthKey: 'OneMonthKey',
  threeMonthKey: 'ThreeMonthsKey',
  twelveMonthsKey: 'TwelveMonthsKey',
}

const influencerStatuses = {
  active: 'active',
  notActive: 'notActive',
}

export const appConfig = {
  userTypes,
  menu: [
    {
      route: ROUTES.ROOT,
      title: 'Главная',
    },
    {
      route: ROUTES.USERS,
      title: 'Подписчики',
      // icon: 'TeamOutlined',
    },
    {
      route: ROUTES.INFLUENCERS,
      title: 'Инфлюенсеры',
      // icon: 'TeamOutlined',
    },
    {
      route: ROUTES.ADMINS,
      title: 'Администраторы',
      // icon: 'TeamOutlined',
    },
    {
      route: ROUTES.PAYMENT_OPERATIONS,
      title: 'Операции оплаты',
      // icon: 'TeamOutlined',
    },
    {
      route: ROUTES.PROMO,
      title: 'Промофункционал',
      // icon: 'TeamOutlined',
    },
    {
      route: ROUTES.PAYMENT_DATA,
      title: 'Данные оплаты',
    },
  ],
  cdnImagesSizeRange: 3,
  cdnPath: {
    root: 'https://insider-cache.cdnvideo.ru',
    insider: 'https://insider-cache.cdnvideo.ru/insider',
    insiderWithoutProtocol: 'insider-cache.cdnvideo.ru/insider',
    media: 'https://insider-cache.cdnvideo.ru/insider/media',
    images: 'https://insider-cache.cdnvideo.ru/insider/images',
    icons: 'https://insider-cache.cdnvideo.ru/insider/images/icons',
    iconsSocial: 'https://insider-cache.cdnvideo.ru/insider/images/social',
    iconsSocialSvg: 'https://insider-cache.cdnvideo.ru/insider/images/iconssvg/social',
    iconsSvg: 'https://insider-cache.cdnvideo.ru/insider/images/iconssvg',
  },
  payment: {
    types: paymentTypes,
    typeLabels: {
      [paymentTypes.donate]: 'Донат',
      [paymentTypes.subscribe]: 'Подписка',
      [paymentTypes.vipChat]: 'Премиум чат',
    },
  },
  dateFormats: {
    shortDate: 'DD-MM-YYYY',
    shortDateDoc: 'DD.MM.YYYY',
    apiDateTime: 'DD.MM.YYYY HH:mm:ss',
    apiDateTimeT: "yyyy-MM-dd'T'HH:mm:ss'Z'",
    apiDateTimeT2: "dd.MM.yyyy'T'HH:mm:ss'Z'",
    apiDateTimeT3: 'YYYY-MM-DDTHH:mm:ssZ',
    apiDateTimeAllDay: 'YYYY-MM-DDT23:59:59Z',
  },
  subscribe: {
    types: periodTypes,
    periodLabels: {
      [periodTypes.oneMonthKey]: '1 мес',
      [periodTypes.threeMonthKey]: '3 мес',
      [periodTypes.twelveMonthsKey]: '12 мес',
    },
    periodMonthValue: {
      [periodTypes.oneMonthKey]: 1,
      [periodTypes.threeMonthKey]: 3,
      [periodTypes.twelveMonthsKey]: 12,
    },
    firstLimit: 100000,
    rent: 0.1,
  },
  filters: {
    storageName: 'filters',
    listNames: {
      users: 'users',
      paymentOperations: 'paymentOperations',
      payHistory: 'payHistory',
      earningsList: 'earningsList',
    },
  },
  influencerStatuses: {
    values: influencerStatuses,
    labels: {
      [influencerStatuses.active]: 'Активен',
      [influencerStatuses.notActive]: 'Не активен',
    },
  },
  passwordLength: {
    min: 6,
    max: 24,
  },
}
