import React from 'react'
import PropTypes from 'prop-types'
import FlagValue from '../FormItems/FlagValue'

function UserAuthData({ user }) {
  return (
    <div className="user-auth-data">
      <div>
        <b>Наличие пароля:</b> {user ? <FlagValue value={user.isHavingPassword} onlyIcon /> : null}
      </div>
      <div>
        <b>Yandex:</b> <FlagValue value={user?.socialIdYa} />
      </div>
      <div>
        <b>VK:</b> <FlagValue value={user?.socialIdVk} />
      </div>
      <div>
        <b>FB:</b> <FlagValue value={user?.socialIdFb} />
      </div>
      <div>
        <b>Google:</b> <FlagValue value={user?.isHavingGoogleSocialId} onlyIcon />
      </div>
    </div>
  )
}

UserAuthData.propTypes = {
  user: PropTypes.object,
}

export default UserAuthData
