import React from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../../constants/appConfig'
import { useWebpLinks } from '../../hooks/useWebpLinks'

const emptyAvatar = `${appConfig.cdnPath.iconsSvg}/emptyavatar.svg`

function UserAvatar({ src = null, size, className, round = true, showEmptyOnLoad = true }) {
  let avatarSrc = src
  if (!avatarSrc?.includes('//')) {
    avatarSrc = `//${avatarSrc}`
  }
  const { link } = useWebpLinks({
    path: avatarSrc,
    params: { w: size * appConfig.cdnImagesSizeRange },
    isOriginal: true,
  })
  if (!src && !showEmptyOnLoad) {
    return null
  }

  return (
    <img
      className={className}
      src={src ? link : emptyAvatar}
      alt=""
      width={`${size}px`}
      height="auto"
      style={{
        height: 'auto',
        width: size,
        ...(round && { borderRadius: size / 2 }),
      }}
    />
  )
}

export default UserAvatar

UserAvatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.number.isRequired,
  className: PropTypes.string,
  round: PropTypes.bool,
  showEmptyOnLoad: PropTypes.bool,
}
