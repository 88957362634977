import React from 'react'
import { useParams } from 'react-router-dom'
import ReportAgent from '../../Components/Reports/ReportAgent'

function Influencer() {
  const { nick } = useParams()
  return <ReportAgent nick={nick} />
}

export default Influencer
