import React from 'react'
import { useParams } from 'react-router-dom'
import InfluencersList from './InfluencersList'
import InfluencerPage from './InfluencerPage'

function Influencers() {
  const { userId } = useParams()
  return userId ? <InfluencerPage userId={userId} /> : <InfluencersList />
}

export default Influencers
