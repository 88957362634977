import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../../constants/appConfig'
import { Button, message, Modal } from 'antd'
import { useToggleInfluencerActive } from '../../api/administrator'

const statusLabes = appConfig.influencerStatuses.labels

function InluencerStatus({ data }) {
  const {
    mutate: toggleActive,
    isLoading: isLoadingToggle,
    isError: isErrorToggle,
  } = useToggleInfluencerActive()

  useEffect(() => {
    if (isErrorToggle) {
      message.error('Ошибка сохранения данных')
    }
  }, [isErrorToggle])

  const getEnableErrors = () => {
    const needElements = []
    if (!data?.influencer?.nickName) {
      needElements.push('никнэйм')
    }
    if (!(data?.influencer?.chatCost > 0)) {
      needElements.push('стоимость премиум чата')
    }
    if (
      !(data?.influencer?.subscription?.oneMonthCost > 0) ||
      !(data?.influencer?.subscription?.threeMonthCost > 0) ||
      !(data?.influencer?.subscription?.twelveMonthCost > 0)
    ) {
      needElements.push('стоимость подписки')
    }
    return needElements
  }

  const errorEnableModal = () => {
    const needElements = getEnableErrors()
    Modal.error({
      title: 'Ошибка смены статуса',
      content: (
        <>
          У инфлюенсера не заполнены:
          <br />
          {needElements.join(', ')}
        </>
      ),
    })
  }

  const confirmModal = () => {
    Modal.confirm({
      title: 'Уверены, что хотите активировать/деактивировать аккаунт?',
      onOk: toggleStatus,
    })
  }

  const toggleStatus = () => {
    const needElements = getEnableErrors()
    if (needElements?.length > 0) {
      errorEnableModal()
    } else {
      toggleActive(data?.influencer?.id)
    }
  }

  return (
    <div className="influencer-status">
      <div>
        <b>Статус аккаунта</b>
      </div>
      <div>{data?.influencer?.isActive ? statusLabes.active : statusLabes.notActive}</div>
      <Button type="primary" size="small" onClick={confirmModal} loading={isLoadingToggle}>
        {data?.influencer?.isActive ? 'Выключить' : 'Включить'}
      </Button>
    </div>
  )
}

InluencerStatus.propTypes = {
  data: PropTypes.object,
}

export default InluencerStatus
