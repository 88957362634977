import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { Button, Col, Input, message, Row, Spin, Typography } from 'antd'
import PaymentDataItem from './PaymentDataItem'
import { usePaymentInformation } from '../../api/administrator'
import { useHistory, useLocation } from 'react-router-dom'
import { ROUTES } from '../../constants/routes'

const { Title } = Typography
function PaymentData() {
  const location = useLocation()
  const history = useHistory()
  const params = queryString.parse(location.search)
  const [orderId, setOrderId] = useState(params?.orderId)
  const { data, isLoading, refetch, isError } = usePaymentInformation(
    { orderId },
    {
      enabled: false,
      retry: false,
    },
  )
  useEffect(() => {
    if (isError) {
      message.error('Ошибка при выполнении запроса. Проверьте ID заказа.')
    }
  }, [isError])

  return (
    <div className="paymentData">
      <Title level={3}>Данные оплаты</Title>
      <Spin spinning={isLoading}>
        <Row gutter={15}>
          <Col>ID заказа:</Col>
          <Col>
            <Input
              onChange={(event) => {
                setOrderId(event.target.value)
              }}
              allowClear
              value={orderId}
            />
          </Col>
          <Col>
            <Button
              onClick={() => {
                history.push({
                  pathname: ROUTES.PAYMENT_DATA,
                  search: `?orderId=${orderId}`,
                })
                refetch()
              }}
              type="primary"
              disabled={!orderId}
            >
              Отправить
            </Button>
          </Col>
        </Row>
      </Spin>
      <div className="data">
        {data?.data?.orderDataResponseItems?.map((item, index) => (
          <>
            <PaymentDataItem key={`${item.dataCategory}-${index}`} data={item} />
          </>
        ))}
      </div>
    </div>
  )
}

export default PaymentData
