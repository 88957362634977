import React from 'react'
import { Typography, Table } from 'antd'
import UserAvatar from '../../Components/Profile/UserAvatar'
import { useHistory } from 'react-router-dom'
import { makeUrl } from '../../helpers'
import { ROUTES } from '../../constants/routes'
// import SearchTool from '../Users/SearchTool'
import { useInfluencers } from '../../api/influencers'
import { appConfig } from '../../constants/appConfig'
import InfluencerAdd from '../../Components/Profile/InfluencerAdd'

const { Title } = Typography

function InfluencersList() {
  const history = useHistory()

  const { data } = useInfluencers()

  const handleRow = (record) => {
    return {
      onClick: () => {
        history.push(
          makeUrl({
            route: ROUTES.INFLUENCERS,
            routeParams: { userId: record.userId },
          }),
        )
      },
    }
  }

  const columns = [
    {
      title: 'Ник',
      dataIndex: 'influencerNickname',
      key: 'influencerNickname',
      render: (nickname, record) => {
        return (
          <>
            <UserAvatar src={record.influencerAvatarLink} size={25} /> {nickname || record.email}
          </>
        )
      },
    },
    {
      title: 'Описание',
      dataIndex: 'influencerShortDescription',
      key: 'influencerShortDescription',
    },
    {
      title: 'Статус',
      dataIndex: 'isActive',
      key: 'isActive',
      width: 150,
      render: (isActive) =>
        isActive
          ? appConfig.influencerStatuses.labels.active
          : appConfig.influencerStatuses.labels.notActive,
    },
  ]

  return (
    <div className="users">
      <div className="header-with-button">
        <Title level={3}>Инфлюенсеры</Title>
        <InfluencerAdd />
      </div>
      {/*<SearchTool />*/}
      <Table
        pagination={false}
        columns={columns}
        dataSource={data?.data || []}
        onRow={handleRow}
        rowKey={(record) => record.id}
      />
    </div>
  )
}

export default InfluencersList
