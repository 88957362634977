export const ROUTES = {
  ROOT: '/',
  AUTH: '/auth',
  USERS: '/users/:userId?',
  PAYMENT_OPERATIONS: '/payment/operations/',
  INFLUENCERS: '/influencers/:userId?',
  ADMINS: '/admins/',
  REPORTS_INFLUENCER: '/reports/influencer/:nick?',
  PROMO: '/promo/',
  PAYMENT_DATA: '/payment/data/',
}
