import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'

const { Search } = Input

function ColumnSocialSearch({ title, onSearch, onChange, style, value }) {
  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <>
      {title}
      <br />
      <Search
        allowClear
        placeholder="SocialId"
        onSearch={onSearch}
        onChange={handleChange}
        style={style}
        value={value}
      />
    </>
  )
}

ColumnSocialSearch.defaultProps = {
  title: 'SocialId',
  onSearch: () => {},
  onChange: () => {},
  style: { width: '200px' },
}

ColumnSocialSearch.propTypes = {
  title: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  style: PropTypes.object,
  value: PropTypes.string,
}

export default ColumnSocialSearch
