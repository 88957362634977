import React, { forwardRef, useEffect, useImperativeHandle, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Button, Table, Modal, message } from 'antd'
import { Link } from 'react-router-dom'
import { formatDate } from '../../helpers/time'
import { usePayments } from '../../api/users'
import { getFilters, makeUrl, removeFilters, setFilters, simpleReducer } from '../../helpers'
import { appConfig } from '../../constants/appConfig'
import ColumnDateSearch from '../FormItems/ColumnDateSearch'
import ColumnNickSearch from '../FormItems/ColumnNickSearch'
import ColumnPayTypeSearch from '../FormItems/ColumnPayTypeSearch'
import ColumnCardSearch from '../FormItems/ColumnCardSearch'
import ColumnOrderIdSearch from '../FormItems/ColumnOrderIdSearch'
import ColumnPaymentIdSearch from '../FormItems/ColumnPaymentIdSearch'
import { ROUTES } from '../../constants/routes'
import moment from 'moment'
import { useReturnPayment } from '../../api/administrator'

const EarningsList = forwardRef(({ nick }, ref) => {
  const initialState = {
    dateFrom: null,
    dateTo: null,
    followerNick: null,
    paymentType: null,
    orderId: null,
    paymentId: null,
    pan: null,
    filterValues: null,
    page: 0,
    processId: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  useImperativeHandle(ref, () => ({
    clearFilters: () => {
      setState(initialState)
      removeFilters(appConfig.filters.listNames.earningsList)
    },
  }))

  useEffect(() => {
    const initialFilters = getFilters(appConfig.filters.listNames.earningsList)
    const dateFilters = {
      dateFrom: initialFilters?.dateFrom
        ? moment(initialFilters?.dateFrom, appConfig.dateFormats.apiDateTimeT3)
        : null,
      dateTo: initialFilters?.dateTo
        ? moment(initialFilters?.dateTo, appConfig.dateFormats.apiDateTimeT3)
        : null,
    }
    setState({
      filterValues: {
        ...initialFilters,
        ...dateFilters,
      },
      ...dateFilters,
      influencerNick: initialFilters?.influencerNick || null,
      followerNick: initialFilters?.followerNick || null,
      paymentType: initialFilters?.paymentType || null,
      orderId: initialFilters?.orderId || null,
      paymentId: initialFilters?.paymentId || null,
      pan: initialFilters?.pan || null,
    })
  }, [])

  const { data, isLoading, refetch } = usePayments(
    {
      followerNick: state.followerNick,
      dateFrom: state.dateFrom?.format(appConfig.dateFormats.apiDateTimeT3),
      dateTo: state.dateTo?.format(appConfig.dateFormats.apiDateTimeAllDay),
      influencerNick: nick,
      paymentType: state.paymentType,
      paymentId: state.paymentId,
      orderId: state.orderId,
      pan: state.pan,
      page: state.page,
      showAllPayments: true,
    },
    {
      enabled: Boolean(nick),
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )

  const { mutate: returnPayment, isLoading: isLoadingBackPay } = useReturnPayment({
    onSuccess: () => {
      setState({ processId: null })
      refetch()
    },
    onError: () => {
      setState({ processId: null })
      message.error('Не удалось вернуть плтеж')
    },
  })

  const renderActions = (row) => {
    return (
      <Button
        size="small"
        onClick={() => {
          Modal.confirm({
            content: 'Вернуть платеж?',
            onOk: () => {
              setState({ processId: row.paymentId })
              returnPayment({ paymentId: row.paymentId })
            },
          })
        }}
        loading={isLoadingBackPay && state.processId === row.paymentId}
      >
        Вернуть платеж
      </Button>
    )
  }

  const columns = [
    {
      title: (
        <ColumnDateSearch
          onChange={(value) => {
            if (value) {
              const [dateFrom, dateTo] = value
              const dateValues = { dateFrom, dateTo }
              setState({
                ...dateValues,
                filterValues: { ...state.filterValues, ...dateValues },
                page: 0,
              })
              setFilters(appConfig.filters.listNames.earningsList, {
                dateFrom: dateFrom.format(appConfig.dateFormats.apiDateTimeT3),
                dateTo: dateTo.format(appConfig.dateFormats.apiDateTimeAllDay),
              })
            } else {
              const dateValues = { dateFrom: null, dateTo: null }
              setState({
                ...dateValues,
                filterValues: { ...state.filterValues, ...dateValues },
                page: 0,
              })
              setFilters(appConfig.filters.listNames.earningsList, dateValues)
            }
          }}
          value={[state.filterValues?.dateFrom || null, state.filterValues?.dateTo || null]}
        />
      ),
      dataIndex: 'createDate',
      key: 'createDate',
      render: (createDate) => formatDate(createDate),
    },
    {
      title: (
        <ColumnNickSearch
          title="Фолловер"
          onSearch={(followerNick) => {
            setState({ followerNick, page: 0 })
            setFilters(appConfig.filters.listNames.earningsList, { followerNick })
          }}
          onChange={(followerNick) =>
            setState({
              filterValues: {
                ...state.filterValues,
                followerNick,
              },
            })
          }
          value={state.filterValues?.followerNick}
        />
      ),
      dataIndex: 'followerNickName',
      key: 'followerNickName',
      render: (followerNickName, row) => (
        <Link to={makeUrl({ route: ROUTES.USERS, routeParams: { userId: row.followerId } })}>
          {followerNickName}
        </Link>
      ),
    },
    {
      title: (
        <ColumnPayTypeSearch
          onChange={(paymentType) => {
            setState({
              paymentType,
              filterValues: {
                ...state.filterValues,
                paymentType,
              },
              page: 0,
            })
            setFilters(appConfig.filters.listNames.earningsList, { paymentType })
          }}
          value={state.filterValues?.paymentType}
        />
      ),
      dataIndex: ['paymentType', 'name'],
      key: 'paymentType',
    },
    {
      title: 'Детали оплаты',
      dataIndex: 'paymentDetail',
      key: 'paymentDetail',
    },
    {
      title: 'Сумма',
      dataIndex: 'paymentSum',
      key: 'paymentSum',
    },
    {
      title: (
        <ColumnCardSearch
          onSearch={(pan) => {
            setState({ pan, page: 0 })
            setFilters(appConfig.filters.listNames.earningsList, { pan })
          }}
          onChange={(pan) =>
            setState({
              filterValues: {
                ...state.filterValues,
                pan,
              },
            })
          }
          value={state.filterValues?.pan}
        />
      ),
      dataIndex: 'bankCardId',
      key: 'bankCardId',
      render: (bankCardId, record) => (
        <>
          {record.bankCardPan} {record.bankCardType?.id} {record.bankCardExpiration}
        </>
      ),
    },
    {
      title: (
        <ColumnOrderIdSearch
          onSearch={(orderId) => {
            setState({ orderId, page: 0 })
            setFilters(appConfig.filters.listNames.earningsList, { orderId })
          }}
          onChange={(orderId) =>
            setState({
              filterValues: {
                ...state.filterValues,
                orderId,
              },
            })
          }
          value={state.filterValues?.orderId}
        />
      ),
      dataIndex: 'orderId',
      key: 'orderId',
    },
    {
      title: (
        <ColumnPaymentIdSearch
          onSearch={(paymentId) => {
            setState({ paymentId, page: 0 })
            setFilters(appConfig.filters.listNames.earningsList, { paymentId })
          }}
          onChange={(paymentId) =>
            setState({
              filterValues: {
                ...state.filterValues,
                paymentId,
              },
            })
          }
          value={state.filterValues?.paymentId}
        />
      ),
      dataIndex: 'paymentId',
      key: 'paymentId',
    },
    {
      title: 'Действия',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (paymentStatus, row) => (
        <>{paymentStatus?.id === 'Charged' ? renderActions(row) : 'Недоступно'}</>
      ),
    },
  ]

  return (
    <div className="income-list">
      <Table
        className="table-col-header-top"
        loading={isLoading}
        columns={columns}
        dataSource={data?.data?.data || []}
        rowKey={(record) => record.id}
        pagination={{
          onChange: (nextPage) => {
            setState({ page: nextPage - 1 })
          },
          current: state.page + 1,
          pageSize: 10,
          showSizeChanger: false,
          total: data?.data?.nav?.total,
        }}
      />
    </div>
  )
})

EarningsList.propTypes = {
  nick: PropTypes.string,
}

export default EarningsList
