import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Auth from './Screens/Auth/Auth'
import Main from './Screens/Main/Main'
import { ROUTES } from './constants/routes'
import { auth } from './api/auth'
import Users from './Screens/Users/Users'
import Influencers from './Screens/Influencers/Influencers'
import Admins from './Screens/Admins/Admins'
import PaymentOperations from './Screens/PaymentOperations/PaymentOperations'
import ReportInfluencer from './Screens/Reports/Influencer'
import Promo from './Screens/Promo/Promo'
import PaymentData from './Screens/PaymentData/PaymentData'

function Routes() {
  const getRedirect = () => {
    return !auth.isLogged() && <Redirect from="*" to={ROUTES.AUTH} />
  }

  return (
    <Switch>
      <Route path={ROUTES.AUTH} exact component={Auth} />
      {getRedirect()}

      <Route path={ROUTES.ROOT} exact component={Main} />
      <Route path={ROUTES.USERS} exact component={Users} />
      <Route path={ROUTES.INFLUENCERS} exact component={Influencers} />
      <Route path={ROUTES.REPORTS_INFLUENCER} exact component={ReportInfluencer} />
      <Route path={ROUTES.ADMINS} exact component={Admins} />
      <Route path={ROUTES.PAYMENT_OPERATIONS} exact component={PaymentOperations} />
      <Route path={ROUTES.PROMO} exact component={Promo} />
      <Route path={ROUTES.PAYMENT_DATA} exact component={PaymentData} />
    </Switch>
  )
}
export default Routes
