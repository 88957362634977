import React, { useEffect, useReducer } from 'react'
import { Typography, Table, Button } from 'antd'
import UserAvatar from '../../Components/Profile/UserAvatar'
import { useHistory } from 'react-router-dom'
import {
  formatSocialId,
  getFilters,
  makeUrl,
  removeFilters,
  setFilters,
  simpleReducer,
} from '../../helpers'
import { ROUTES } from '../../constants/routes'
import { useUsers } from '../../api/users'
import ColumnNickSearch from '../../Components/FormItems/ColumnNickSearch'
import ColumnEmailSearch from '../../Components/FormItems/ColumnEmailSearch'
import { appConfig } from '../../constants/appConfig'
import ColumnSocialSearch from '../../Components/FormItems/ColumnSocialSearch'
import UserSocial from '../../Components/Profile/UserSocial'

const { Title } = Typography

function UsersList() {
  const history = useHistory()

  const initialState = {
    email: null,
    nickname: null,
    socialId: null,
    filterValues: null,
    userList: [],
    total: 0,
    page: 0,
    countPerPage: 10,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  useEffect(() => {
    const initialFilters = getFilters(appConfig.filters.listNames.users)
    setState({
      filterValues: initialFilters,
      email: initialFilters?.email || null,
      nickname: initialFilters?.nickname || null,
      socialId: initialFilters?.socialId || null,
    })
  }, [])

  const {
    data,
    isSuccess,
    isLoading,
    refetch: refetchUsers,
  } = useUsers(
    {
      email: state.email,
      nickname: state.nickname,
      socialId: state.socialId,
      page: state.page,
      countPerPage: state.countPerPage,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )

  useEffect(() => {
    if (isSuccess && data) {
      setState({ userList: data?.data?.userList, total: data?.data?.userTotalCount })
    }
  }, [isSuccess, data])

  const handleRow = (record) => {
    return {
      onClick: () => {
        history.push(
          makeUrl({
            route: ROUTES.USERS,
            routeParams: { userId: record?.follower?.id },
          }),
        )
      },
    }
  }

  const handleSocialChange = (socialId) => {
    setState({
      filterValues: {
        ...state.filterValues,
        socialId,
      },
    })
  }

  const handleSocialSearch = (socialId) => {
    setState({ socialId, page: 0 })
    setFilters(appConfig.filters.listNames.users, { socialId })
  }

  const columns = [
    {
      title: (
        <ColumnNickSearch
          onSearch={(nickname) => {
            setState({ nickname, page: 0 })
            setFilters(appConfig.filters.listNames.users, { nickname })
          }}
          onChange={(nickname) =>
            setState({
              filterValues: {
                ...state.filterValues,
                nickname,
              },
            })
          }
          value={state.filterValues?.nickname}
        />
      ),
      width: 250,
      dataIndex: ['follower', 'nickName'],
      key: 'nickname',
      render: (nickname, record) => {
        return (
          <>
            <UserAvatar src={record?.follower?.avatarUrl} size={25} /> {nickname}
          </>
        )
      },
    },
    {
      title: (
        <ColumnEmailSearch
          onSearch={(email) => {
            setState({ email, page: 0 })
            setFilters(appConfig.filters.listNames.users, { email })
          }}
          onChange={(email) =>
            setState({
              filterValues: {
                ...state.filterValues,
                email,
              },
            })
          }
          value={state.filterValues?.email}
        />
      ),
      width: 250,
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: (
        <ColumnSocialSearch
          onSearch={handleSocialSearch}
          onChange={handleSocialChange}
          value={state.filterValues?.socialId}
        />
      ),
      dataIndex: ['follower', 'socialId'],
      render: (socialId) => formatSocialId(socialId),
      key: 'socialId',
    },
  ]

  const handleClearFilters = () => {
    setState(initialState)
    removeFilters(appConfig.filters.listNames.users)
    refetchUsers()
  }

  return (
    <div className="users">
      <div className="header-with-button">
        <Title level={3}>Подписчики</Title>
        <div className="header-filters">
          <UserSocial
            onSelect={(socialId) => {
              handleSocialChange(socialId)
              handleSocialSearch(socialId)
            }}
            onChangeString={(socialString) => {
              setState({
                filterValues: {
                  ...state.filterValues,
                  socialString,
                },
              })
              setFilters(appConfig.filters.listNames.users, { socialString })
            }}
            socialString={state.filterValues?.socialString}
          />
          <Button type="primary" onClick={handleClearFilters}>
            Очистить все фильтры
          </Button>
        </div>
      </div>
      <Table
        size="small"
        loading={isLoading}
        columns={columns}
        dataSource={state.userList || []}
        onRow={handleRow}
        pagination={{
          onChange: (nextPage, pageSize) => {
            setState({ page: nextPage - 1, countPerPage: pageSize })
          },
          total: state.total,
          current: state.page + 1,
          showTotal: (total) => `Всего ${total} записей`,
        }}
      />
    </div>
  )
}

export default UsersList
