import { api } from './api'
import { useQuery } from 'react-query'

export const INFLUENCERS_KEY = 'influencerList'

const getInfluencerList = () => {
  return api.get({
    path: `/insider/administrator/influencers`,
  })
}

export const useInfluencers = (params) => {
  return useQuery([INFLUENCERS_KEY], getInfluencerList, params)
}

const getInfluencerByNick = (nick) => {
  return api.get({
    path: `/insider/influencer/${nick}/profile`,
  })
}

export const useInfluencerByNick = (nick, params) => {
  return useQuery([INFLUENCERS_KEY, nick], () => getInfluencerByNick(nick), params)
}
