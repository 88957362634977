import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, DatePicker } from 'antd'
import { formatDate, getDate } from '../../../helpers/time'
import moment from 'moment'
import { appConfig } from '../../../constants/appConfig'

function EditableDate({ date = null, onChange }) {
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(moment())

  useEffect(() => {
    setValue(moment(getDate(date || new Date()), appConfig.dateFormats.shortDate))
  }, [date])

  return isEditing ? (
    <div>
      <DatePicker onChange={setValue} value={value} format={appConfig.dateFormats.shortDate} />
      <Button
        size="small"
        type="primary"
        onClick={() => {
          setIsEditing(false)
          if (value) {
            onChange(value)
          }
        }}
      >
        Сохранить
      </Button>
    </div>
  ) : (
    <div>
      {date ? formatDate(date) : 'не указано'}
      <Button size="small" type="primary" onClick={() => setIsEditing(true)}>
        Изменить
      </Button>
    </div>
  )
}

export default EditableDate

EditableDate.propTypes = {
  date: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}
