import React from 'react'
import AddLikes from './AddLikes'

function Promo() {
  return (
    <div className="promo">
      <AddLikes />
    </div>
  )
}

export default Promo
