import React from 'react'
import PropTypes from 'prop-types'
import { Input } from 'antd'

const { Search } = Input

function ColumnCardSearch({ title, onSearch, onChange, value }) {
  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <>
      {title}
      <br />
      <Search
        allowClear
        placeholder="Последние 4 цифры карты"
        onSearch={onSearch}
        value={value}
        onChange={handleChange}
      />
    </>
  )
}

ColumnCardSearch.defaultProps = {
  title: 'Карта',
  onSearch: () => {},
  onChange: () => {},
}

ColumnCardSearch.propTypes = {
  title: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default ColumnCardSearch
