import React from 'react'
import PropTypes from 'prop-types'
import { Divider, Table, Typography } from 'antd'
import { parseJSONFirstLevel } from '../../helpers'

const { Title, Paragraph } = Typography

function PaymentDataItem({ data }) {
  const pureTableData = JSON.parse(data.pseudoSQLOutput || '[]')
  const tableData = Array.isArray(pureTableData)
    ? pureTableData.map((data) => {
        return parseJSONFirstLevel(JSON.stringify(data))
      })
    : parseJSONFirstLevel(data.pseudoSQLOutput)

  let columns = []
  const firstData = Array.isArray(tableData) ? tableData[0] : tableData

  if (typeof firstData === 'object' && firstData !== null) {
    columns = Object.keys(firstData).map((key) => ({
      title: key,
      dataIndex: key,
      key,
    }))
  }

  return (
    <div className="item">
      <Divider orientation="left">
        <Title level={5}>{data.dataCategory}</Title>
      </Divider>
      <Paragraph code>{data.pseudoSQLInput}</Paragraph>
      {columns.length > 0 ? (
        <Table
          bordered
          size="small"
          columns={columns}
          dataSource={Array.isArray(tableData) ? tableData : [tableData]}
          pagination={false}
          scroll={{
            x: 'max-content',
          }}
        />
      ) : (
        <Paragraph>Нет данныx</Paragraph>
      )}
    </div>
  )
}
export default PaymentDataItem

PaymentDataItem.propTypes = {
  data: PropTypes.shape({
    dataCategory: PropTypes.string,
    pseudoSQLInput: PropTypes.string,
    pseudoSQLOutput: PropTypes.string,
  }),
}
