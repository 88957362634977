import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  InputNumber,
  Input,
  Form,
  Table,
  Popconfirm,
  DatePicker,
  Select,
  Switch,
  Button,
} from 'antd'
import { EditOutlined } from '@ant-design/icons'
import moment from 'moment'
import { getDate } from '../../../helpers/time'
import { appConfig } from '../../../constants/appConfig'

const EditableCell = ({
  editing,
  dataIndex,
  title,
  field,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = () => {
    switch (field?.type) {
      case 'DatePicker':
        return <DatePicker format={appConfig.dateFormats.shortDate} />
      case 'InputNumber':
        return <InputNumber />
      case 'Switch':
        return <Switch />
      case 'Select':
        return (
          <Select>
            {field.options?.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        )
      default:
        return <Input />
    }
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          {...(field?.type === 'Switch' && { valuePropName: 'checked' })}
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Поле ${title} обязательное`,
              ...(field?.type === 'DatePicker' && {
                type: 'object',
              }),
            },
          ]}
        >
          {inputNode()}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

EditableCell.propTypes = {
  editing: PropTypes.bool,
  dataIndex: PropTypes.string,
  title: PropTypes.string,
  field: PropTypes.shape({
    type: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
  }),
  record: PropTypes.shape({}),
  index: PropTypes.number,
  children: PropTypes.array,
}

const EditableTable = ({ originColumns, originData, rowKey, onSave, ...restProps }) => {
  const [form] = Form.useForm()
  const [data, setData] = useState([])
  const [editingKey, setEditingKey] = useState('')

  useEffect(() => {
    setData(originData)
  }, [originData])

  const isEditing = (record) => record[rowKey] === editingKey

  const edit = (record) => {
    const dateFields =
      originColumns
        ?.filter(
          (column) => column.editable === true && column.editableField?.type === 'DatePicker',
        )
        ?.map((field) => field.dataIndex) || []
    form.setFieldsValue({
      ...record,
      ...dateFields?.reduce((result, item) => {
        result[item] = moment(getDate(record[item], appConfig.dateFormats.shortDate))
        return result
      }, {}),
    })
    setEditingKey(record[rowKey])
  }

  const cancel = () => {
    setEditingKey('')
  }

  const save = async (key) => {
    try {
      const row = await form.validateFields()

      const newData = [...data]
      const index = newData.findIndex((item) => key === item[rowKey])
      if (index > -1) {
        const item = newData[index]
        if (onSave) {
          onSave(row, item)
        }
        newData.splice(index, 1, {
          ...item,
          ...row,
        })
        setData(newData)
        setEditingKey('')
      } else {
        newData.push(row)
        setData(newData)
        setEditingKey('')
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo)
    }
  }

  const columns = [
    ...originColumns,
    {
      title: 'Действия',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span>
            <Button type="link" onClick={() => save(record[rowKey])} style={{ marginRight: 8 }}>
              Сохранить
            </Button>
            <Popconfirm title="Отменить изменения?" cancelText="Отмена" onConfirm={cancel}>
              <Button type="link">Отмена</Button>
            </Popconfirm>
          </span>
        ) : (
          <Button type="link" disabled={editingKey !== ''} onClick={() => edit(record)}>
            <EditOutlined />
          </Button>
        )
      },
    },
  ]

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        field: col.editableField || {},
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          onChange: cancel,
        }}
        {...restProps}
      />
    </Form>
  )
}

export default EditableTable

EditableTable.propTypes = {
  originColumns: PropTypes.array.isRequired,
  originData: PropTypes.array.isRequired,
  rowKey: PropTypes.string.isRequired,
  onSave: PropTypes.func,
}
