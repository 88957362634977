import React, { useEffect, useReducer, useState } from 'react'
import { Typography, Table, Button, Modal, message } from 'antd'
import { getFilters, makeUrl, removeFilters, setFilters, simpleReducer } from '../../helpers'
import { usePaymentList, useReturnPayment } from '../../api/administrator'
import { formatDateTime } from '../../helpers/time'
import { appConfig } from '../../constants/appConfig'
import ColumnDateSearch from '../../Components/FormItems/ColumnDateSearch'
import ColumnNickSearch from '../../Components/FormItems/ColumnNickSearch'
import ColumnPayTypeSearch from '../../Components/FormItems/ColumnPayTypeSearch'
import ColumnOrderIdSearch from '../../Components/FormItems/ColumnOrderIdSearch'
import ColumnPaymentIdSearch from '../../Components/FormItems/ColumnPaymentIdSearch'
import ColumnCardSearch from '../../Components/FormItems/ColumnCardSearch'
import { ROUTES } from '../../constants/routes'
import { Link } from 'react-router-dom'
import moment from 'moment'

const { Title } = Typography

function PaymentOperationsList() {
  const [page, setPage] = useState(0)

  const initialState = {
    influencerNick: undefined,
    followerNick: undefined,
    orderId: undefined,
    paymentType: undefined,
    dateFrom: undefined,
    dateTo: undefined,
    pan: undefined,
    paymentId: undefined,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const [stateFilter, setStateFilter] = useReducer(simpleReducer, null)

  const initialStateUI = {
    processId: null,
  }
  const [stateUI, setStateUI] = useReducer(simpleReducer, initialStateUI)

  const { data, isLoading, refetch } = usePaymentList({
    showAllPayments: true,
    page,
    ...state,
    dateFrom: state.dateFrom?.format(appConfig.dateFormats.apiDateTimeT3),
    dateTo: state.dateTo?.format(appConfig.dateFormats.apiDateTimeAllDay),
  })

  useEffect(() => {
    const initialFilters = getFilters(appConfig.filters.listNames.paymentOperations)
    const data = {
      ...initialFilters,
      dateFrom: initialFilters?.dateFrom
        ? moment(initialFilters?.dateFrom, appConfig.dateFormats.apiDateTimeT3)
        : null,
      dateTo: initialFilters?.dateTo
        ? moment(initialFilters?.dateTo, appConfig.dateFormats.apiDateTimeT3)
        : null,
    }
    setState(data)
    setStateFilter(data)
  }, [])

  const { mutate: returnPayment, isLoading: isLoadingBackPay } = useReturnPayment({
    onSuccess: () => {
      setStateUI({ processId: null })
      refetch()
    },
    onError: () => {
      setStateUI({ processId: null })
      message.error('Не удалось вернуть плтеж')
    },
  })
  const handleRow = () => {
    return {
      onClick: () => {},
    }
  }

  const columns = [
    {
      title: (
        <ColumnDateSearch
          title="Дата операции"
          onChange={(value) => {
            if (value) {
              const [dateFrom, dateTo] = value
              setPage(0)
              setState({ dateFrom, dateTo })
              setStateFilter({ dateFrom, dateTo })
              setFilters(appConfig.filters.listNames.paymentOperations, {
                dateFrom: dateFrom.format(appConfig.dateFormats.apiDateTimeT3),
                dateTo: dateTo.format(appConfig.dateFormats.apiDateTimeAllDay),
              })
            } else {
              setPage(0)
              setState({ dateFrom: null, dateTo: null })
              setStateFilter({ dateFrom: null, dateTo: null })
              setFilters(appConfig.filters.listNames.paymentOperations, {
                dateFrom: null,
                dateTo: null,
              })
            }
          }}
          value={[stateFilter?.dateFrom || null, stateFilter?.dateTo || null]}
        />
      ),
      width: 250,
      dataIndex: 'createDate',
      key: 'createDate',
      render: (date) => (date ? <>{formatDateTime(date)}</> : null),
    },
    {
      title: (
        <ColumnNickSearch
          title="Получатель"
          onSearch={(influencerNick) => {
            setPage(0)
            setState({ influencerNick })
            setStateFilter({ influencerNick })
            setFilters(appConfig.filters.listNames.paymentOperations, { influencerNick })
          }}
          onChange={(influencerNick) => {
            setStateFilter({ influencerNick })
          }}
          value={stateFilter?.influencerNick}
        />
      ),
      width: 220,
      dataIndex: 'influencerNickName',
      key: 'influencerNickName',
    },
    {
      title: (
        <ColumnNickSearch
          title="Плательщик"
          onSearch={(followerNick) => {
            setPage(0)
            setState({ followerNick })
            setStateFilter({ followerNick })
            setFilters(appConfig.filters.listNames.paymentOperations, { followerNick })
          }}
          onChange={(followerNick) => {
            setStateFilter({ followerNick })
          }}
          value={stateFilter?.followerNick}
        />
      ),
      width: 220,
      dataIndex: 'followerNickName',
      key: 'followerNickName',
      render: (followerNickName, row) => (
        <Link to={makeUrl({ route: ROUTES.USERS, routeParams: { userId: row.followerId } })}>
          {followerNickName}
        </Link>
      ),
    },
    {
      title: (
        <ColumnPayTypeSearch
          onChange={(paymentType) => {
            setPage(0)
            setState({ paymentType })
            setStateFilter({ paymentType })
            setFilters(appConfig.filters.listNames.paymentOperations, { paymentType })
          }}
          value={stateFilter?.paymentType}
        />
      ),
      width: 150,
      dataIndex: 'paymentType',
      key: 'paymentType',
      render: (paymentType) => <>{paymentType?.name}</>,
    },
    {
      title: (
        <ColumnOrderIdSearch
          onSearch={(orderId) => {
            setPage(0)
            setState({ orderId })
            setFilters(appConfig.filters.listNames.paymentOperations, { orderId })
            setStateFilter({ orderId })
          }}
          onChange={(orderId) => setStateFilter({ orderId })}
          value={stateFilter?.orderId}
        />
      ),
      dataIndex: 'orderId',
      key: 'orderId',
    },
    {
      title: (
        <ColumnPaymentIdSearch
          onSearch={(paymentId) => {
            setPage(0)
            setState({ paymentId })
            setStateFilter({ paymentId })
            setFilters(appConfig.filters.listNames.paymentOperations, { paymentId })
          }}
          onChange={(paymentId) => setStateFilter({ paymentId })}
          value={stateFilter?.paymentId}
        />
      ),
      width: 200,
      dataIndex: 'paymentId',
      key: 'paymentId',
    },
    {
      title: (
        <ColumnCardSearch
          onSearch={(pan) => {
            setPage(0)
            setState({ pan })
            setStateFilter({ pan })
            setFilters(appConfig.filters.listNames.paymentOperations, { pan })
          }}
          onChange={(pan) => setStateFilter({ pan })}
          value={stateFilter?.pan}
        />
      ),
      width: 200,
      dataIndex: 'bankCardPan',
      key: 'bankCardPan',
    },
    {
      title: 'Ид карты',
      dataIndex: 'bankCardId',
      key: 'bankCardId',
    },
    {
      title: 'Срок действия карты',
      width: 100,
      dataIndex: 'bankCardExpiration',
      key: 'bankCardExpiration',
    },
    {
      title: 'Детали оплаты',
      dataIndex: 'paymentDetail',
      key: 'paymentDetail',
    },
    {
      title: 'Сумма',
      width: 100,
      dataIndex: 'paymentSum',
      key: 'paymentSum',
    },
    {
      title: 'Статус',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (paymentStatus) => <>{paymentStatus?.name}</>,
    },
    {
      key: 'actions',
      render: (row) => {
        return (
          row.paymentStatus.id === 'Charged' && (
            <Button
              loading={isLoadingBackPay && stateUI.processId === row.paymentId}
              size="small"
              onClick={() => {
                Modal.confirm({
                  content: 'Вернуть платеж?',
                  onOk: () => {
                    setStateUI({ processId: row.paymentId })
                    returnPayment({ paymentId: row.paymentId })
                  },
                })
              }}
            >
              Вернуть платеж
            </Button>
          )
        )
      },
    },
  ]

  const dataSource = data?.data.data || []
  const total = data?.data.nav.total || 50

  const handleClearFilters = () => {
    setState(initialState)
    setStateFilter(initialState)
    removeFilters(appConfig.filters.listNames.paymentOperations)
  }

  return (
    <div className="users">
      <div className="header-with-button">
        <Title level={3}>Операции оплаты</Title>
        <Button type="primary" onClick={handleClearFilters}>
          Очистить все фильтры
        </Button>
      </div>
      <Table
        className="table-col-header-top"
        pagination={{
          onChange: (nextPage) => {
            setPage(nextPage - 1)
          },
          current: page + 1,
          pageSize: 20,
          total,
          showSizeChanger: false,
        }}
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        onRow={handleRow}
        scroll={{
          y: 'calc(100vh - 350px)',
          x: 'max-content',
        }}
      />
    </div>
  )
}

export default PaymentOperationsList
