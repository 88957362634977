import React, { useEffect, useReducer } from 'react'
import { DatePicker, Row, Col, Spin, Input, InputNumber, Button, Form, message } from 'antd'
import { simpleReducer } from '../../helpers'
import { appConfig } from '../../constants/appConfig'
import { useMutateAgentData, useMutateGetAgentData } from '../../api/administrator'
import PropTypes from 'prop-types'
import moment from 'moment'

const modes = {
  edit: 'edit',
  show: 'show',
}

function AgentData({ influencerId }) {
  const [form] = Form.useForm()

  const initialState = {
    mode: modes.show,
    firstName: null,
    lastName: null,
    patronymic: null,
    agentAgreementId: null,
    agentAgreementDate: null,
    agentCommission: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    mutate: mutateAgent,
    isLoading: isLoadingAgent,
    isSuccess: isSuccessAgent,
    isError: isErrorAgent,
  } = useMutateAgentData()

  useEffect(() => {
    if (isSuccessAgent) {
      const values = form.getFieldsValue()
      setState({
        ...values,
        firstName: values.firstName?.trim(),
        lastName: values.lastName?.trim(),
        patronymic: values.patronymic?.trim(),
        agentAgreementId: values.agentAgreementId?.trim(),
        mode: modes.show,
      })
      form.resetFields()
    } else if (isErrorAgent) {
      message.error('Ошибка сохранения данных')
    }
  }, [isSuccessAgent, isErrorAgent, form])

  const {
    mutate: mutateGetAgent,
    data: dataGetAgent,
    isLoading: isLoadingGetAgent,
    isSuccess: isSuccessGetAgent,
    isError: isErrorGetAgent,
  } = useMutateGetAgentData()

  const handleButton = () => {
    if (state.mode === modes.show) {
      setState({ mode: modes.edit })
    } else {
      form.submit()
    }
  }

  useEffect(() => {
    if (isSuccessGetAgent && dataGetAgent) {
      setState({ ...dataGetAgent.data })
    } else if (isErrorGetAgent) {
      message.error('Ошибка получения данных агента')
    }
  }, [isSuccessGetAgent, isErrorGetAgent, dataGetAgent])

  useEffect(() => {
    if (influencerId) {
      mutateGetAgent({ influencerId })
    }
  }, [influencerId, mutateGetAgent])

  const handleCancel = () => {
    form.resetFields()
    setState({ mode: modes.show })
  }

  const handleFinish = (values) => {
    mutateAgent({
      influencerId,
      data: {
        ...values,
        firstName: values.firstName?.trim(),
        lastName: values.lastName?.trim(),
        patronymic: values.patronymic?.trim(),
        agentAgreementId: values.agentAgreementId?.trim(),
      },
    })
  }

  useEffect(() => {
    if (state.mode === modes.edit) {
      form.setFieldsValue({
        ...state,
        agentAgreementDate: state.agentAgreementDate
          ? moment(state.agentAgreementDate, appConfig.dateFormats.apiDateTime)
          : null,
      })
    }
  }, [state.mode, form, state])

  return (
    <div className="user-card-agent">
      <Spin spinning={isLoadingAgent || isLoadingGetAgent}>
        <b>Данные агента</b>
        <Form form={form} onFinish={handleFinish} requiredMark={false}>
          <Row gutter={24}>
            <Col span={10}>
              <Form.Item
                name="lastName"
                label="Фамилия"
                rules={[{ required: true, message: 'Введите фамилию' }]}
                labelAlign="left"
                labelCol={{ span: 8 }}
              >
                {state.mode === modes.show ? state.lastName : <Input size="small" />}
              </Form.Item>
              <Form.Item
                name="firstName"
                label="Имя"
                rules={[{ required: true, message: 'Введите имя' }]}
                labelAlign="left"
                labelCol={{ span: 8 }}
              >
                {state.mode === modes.show ? state.firstName : <Input size="small" />}
              </Form.Item>
              <Form.Item
                name="patronymic"
                label="Отчество"
                labelAlign="left"
                labelCol={{ span: 8 }}
              >
                {state.mode === modes.show ? state.patronymic : <Input size="small" />}
              </Form.Item>
            </Col>
            <Col span={14}>
              <Form.Item
                name="agentAgreementId"
                label="Номер договора"
                rules={[{ required: true, message: 'Введите номер договора' }]}
                labelAlign="left"
                labelCol={{ span: 14 }}
              >
                {state.mode === modes.show ? state.agentAgreementId : <Input size="small" />}
              </Form.Item>
              <Form.Item
                name="agentAgreementDate"
                label="Дата договора"
                rules={[{ required: true, message: 'Введите дату договора' }]}
                labelAlign="left"
                labelCol={{ span: 14 }}
              >
                {state.mode === modes.edit ? (
                  <DatePicker
                    format={appConfig.dateFormats.shortDate}
                    size="small"
                    style={{ width: '100%' }}
                  />
                ) : state.agentAgreementDate ? (
                  moment(state.agentAgreementDate, appConfig.dateFormats.apiDateTime).format(
                    appConfig.dateFormats.shortDate,
                  )
                ) : null}
              </Form.Item>
              <Form.Item
                name="agentCommission"
                label="Агентское вознаграждение"
                rules={[{ required: true, message: 'Введите агентское вознаграждение' }]}
                labelAlign="left"
                labelCol={{ span: 14 }}
              >
                {state.mode === modes.show ? (
                  `${state.agentCommission}%`
                ) : (
                  <InputNumber
                    min={0}
                    max={100}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                    size="small"
                    style={{ width: '100%' }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row className="buttons">
          <Col>
            <Button size="small" onClick={handleButton} type="primary" disabled={state.error}>
              {state.mode === modes.show ? 'Изменить' : 'Сохранить'}
            </Button>
            {state.mode === modes.edit && (
              <Button size="small" onClick={handleCancel} type="default" className="cancel">
                Отмена
              </Button>
            )}
          </Col>
        </Row>
      </Spin>
    </div>
  )
}

AgentData.propTypes = {
  influencerId: PropTypes.string,
}

export default AgentData
