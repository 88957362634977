import {
  format,
  isThisWeek,
  isThisYear,
  isToday,
  isYesterday,
  isDate,
  isValid,
  differenceInHours,
  differenceInMinutes,
} from 'date-fns'
import { ru } from 'date-fns/locale'
import moment from 'moment'
import { numberDeclension } from '../index'

export function formatMessageTime(timeString) {
  const date = getDate(timeString)
  if (isToday(date)) {
    return format(date, 'HH:mm')
  }
  if (isThisWeek(date)) {
    return format(date, 'E', { locale: ru })
  }
  if (isThisYear(date)) {
    return format(date, 'dd.MM')
  }
  return format(date, 'dd.MM.yy')
}

export function formatMessageTimeForChat(timeString) {
  const date = getDate(timeString)
  if (isToday(date)) {
    return 'Сегодня'
  }
  if (isYesterday(date)) {
    return 'Вчера'
  }
  if (isThisYear(date)) {
    return format(date, 'dd.MM')
  }
  return format(date, 'dd.MM.yy')
}

export function formatPostTime(timeString) {
  const now = new Date()
  const date = getDate(timeString)
  const minutesLeft = differenceInMinutes(now, date)
  if (minutesLeft < 60) {
    return `${minutesLeft} ${numberDeclension(minutesLeft, ['минуту', 'минуты', 'минут'])} назад`
  }
  const hoursLeft = differenceInHours(now, date)
  if (hoursLeft <= 3) {
    return `${hoursLeft === 2 ? 'два' : ''}${hoursLeft === 3 ? 'три' : ''} ${numberDeclension(
      hoursLeft,
      ['час', 'часа', 'часов'],
    )} назад`
  }
  return formatDateTimeLastDays(timeString)
}

export function formatTime(timeString) {
  return format(getDate(timeString), 'HH:mm')
}

export function formatDate(date) {
  return date ? moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY') : ''
}

export function formatDateTime(date) {
  //это бред, но парсер date-fns не всегда отрабатывает
  const dateObj = isDate(date) ? date : moment(date, 'DD.MM.YYYY hh:mm:ssZ').toDate()
  return isValid(dateObj)
    ? format(dateObj, 'dd MMMM yyyy HH:mm', { locale: ru, timeFormat: '24hours' })
    : ''
}

export const isDateExpired = (date) => {
  const now = moment()
  const expDate = moment(date, 'DD.MM.YYYY')
  return expDate.isSameOrBefore(now)
}

export const isDateTimeExpired = (date) => {
  const now = moment()
  const expDate = moment(date, 'DD.MM.YYYY hh:mm:ssZ')
  return expDate.isSameOrBefore(now)
}

export const formatDateTimeLastDays = (dateString) => {
  const dateObj = getDate(dateString)
  if (isToday(dateObj)) {
    return `сегодня в ${format(dateObj, 'HH:mm')}`
  } else if (isYesterday(dateObj)) {
    return `вчера в ${format(dateObj, 'HH:mm')}`
  } else {
    return format(dateObj, 'dd.MM.yyyy в HH:mm')
  }
}

export const getDate = (timeString) => {
  try {
    return isDate(timeString) ? timeString : moment(timeString, 'DD.MM.YYYY hh:mm:ssZ').toDate()
  } catch (e) {
    console.log('Неверный формат даты')
    return new Date()
  }
}
