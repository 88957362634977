import { api, queryClient } from './api'
import { useMutation, useQuery } from 'react-query'

export const INFLUENCER_PAYMENT_KEY = 'influencer-payments'

const getInfluencerPayments = (props) => {
  const params = new URLSearchParams(props)
  return api.get({
    path: `/insider/influencer-payments?${params.toString()}`,
  })
}

export const useInfluencerPayments = (query, params) => {
  return useQuery(
    [INFLUENCER_PAYMENT_KEY, Object.values(query).join('-')],
    () => getInfluencerPayments({ ...query }),
    params,
  )
}

const createInfluencerPayments = (data) => {
  return api.post({
    path: `/insider/influencer-payments`,
    data,
  })
}

export function useMutateCreateInfluencerPayments() {
  return useMutation(createInfluencerPayments, {
    onSuccess: () => {
      queryClient.refetchQueries(INFLUENCER_PAYMENT_KEY, { active: true })
    },
  })
}

const deleteInfluencerPayment = (paymentRecordId) => {
  return api.del({
    path: `/insider/influencer-payments/${paymentRecordId}`,
  })
}

export function useMutateDeleteInfluencerPayment() {
  return useMutation(deleteInfluencerPayment, {
    onSuccess: () => {
      queryClient.refetchQueries(INFLUENCER_PAYMENT_KEY, { active: true })
    },
  })
}
