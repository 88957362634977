import React, { useEffect, useReducer } from 'react'
import { Button, Col, Input, Row, Modal, message } from 'antd'
import { simpleReducer } from '../../helpers'
import { useUsers } from '../../api/users'
import { useAddInfluencer } from '../../api/administrator'
import Text from 'antd/es/typography/Text'
import { appConfig } from '../../constants/appConfig'

const validator = require('email-validator')
const { min: MIN_PASS_LENGTH, max: MAX_PASS_LENGTH } = appConfig.passwordLength

function InfluencerAdd() {
  const initialState = {
    email: null,
    inputEmail: null,
    password: null,
    passwordError: null,
    isVisibleNewModal: false,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const {
    data: dataUsers,
    isSuccess: isSuccessUsers,
    isLoading: isLoadingUsers,
  } = useUsers(
    { email: state.email, exactSearch: true },
    {
      enabled: Boolean(state.email),
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )

  const { mutate: addInfluencer, isSuccess: isSuccessAdd, isError: isErrorAdd } = useAddInfluencer()

  useEffect(() => {
    if (isSuccessUsers && dataUsers) {
      const targetUser = dataUsers?.data?.userList?.[0] // because use param exactSearch
      if (targetUser?.influencer) {
        alreadyExistInfluencerModal(targetUser.email)
      } else if (!targetUser?.influencer && targetUser?.email) {
        confirmAddModal(targetUser.email)
      } else if (validator.validate(state.email)) {
        setState({ isVisibleNewModal: true })
      } else {
        infoFailEmail()
      }
    }
  }, [dataUsers, isSuccessUsers])

  useEffect(() => {
    if (isSuccessAdd) {
      setState(initialState)
    } else if (isErrorAdd) {
      setState(initialState)
      message.error('Ошибка сохранения данных')
    }
  }, [isSuccessAdd, isErrorAdd])

  const handleNewInfluencer = () => {
    if (state.password?.length >= MIN_PASS_LENGTH && state.password?.length <= MAX_PASS_LENGTH) {
      addInfluencer({ email: state.email, password: state.password })
    } else {
      setState({
        passwordError: `Пароль должен содержать от ${MIN_PASS_LENGTH} - ${MAX_PASS_LENGTH} символов`,
      })
    }
  }

  const infoFailEmail = () => {
    Modal.info({
      title: 'Пользователь не найден',
      content: <>Некорректный email</>,
      onOk: () => setState(initialState),
    })
  }

  const alreadyExistInfluencerModal = (email) => {
    Modal.info({
      title: 'Пользователь найден',
      content: (
        <>
          Пользователь <b>{email}</b> уже инфлюенсер
        </>
      ),
      onOk: () => setState(initialState),
    })
  }

  const confirmAddModal = (email) => {
    Modal.confirm({
      title: 'Пользователь найден',
      content: (
        <>
          Создать для пользователя <b>{email}</b> профиль Инфлюенсера?
        </>
      ),
      onOk: () => addInfluencer({ email }),
      onCancel: () => setState(initialState),
    })
  }

  const handleButtonAdd = () => {
    if (state.inputEmail?.length > 0) {
      setState({ email: state.inputEmail })
    }
  }

  return (
    <Row gutter={8}>
      <Col>
        <Input
          placeholder="Email"
          onChange={(e) => setState({ inputEmail: e.target.value?.replace(' ', '') })}
          allowClear
          value={state.inputEmail}
        />
      </Col>
      <Col>
        <Button type="primary" onClick={handleButtonAdd} loading={isLoadingUsers}>
          Добавить Инфлюенсера
        </Button>
      </Col>
      <Modal
        title={`Пользователь в системе не заведен`}
        onOk={handleNewInfluencer}
        onCancel={() => setState(initialState)}
        visible={state.isVisibleNewModal}
        width={400}
      >
        Для создания аккаунта с <b>{state.email}</b> введите пароль
        <Input.Password
          placeholder="Пароль"
          onChange={(e) => {
            setState({ password: e.target.value })
          }}
        />
        <Text type="danger">{state.passwordError}</Text>
      </Modal>
    </Row>
  )
}

export default InfluencerAdd
