import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const clientID = '741805a4cdf449c7a235cb8e747bbe67' //insider.space@yandex.ru
const yaMarker = 'yandexAuth'

function Yandex({ authHandler }) {
  const [host, setHost] = useState('')
  const getRedirectUri = (host) => `${host}/auth/`

  useEffect(() => {
    setHost(window.location.origin)

    const data = new URLSearchParams(window.location.hash.replace('#', ''))
    const yandexData = {}
    data.forEach((value, key) => {
      yandexData[key] = value
    })
    // Открыт попап для Яндекс авторизации. Обработка токена из УРЛа
    if (yandexData.access_token && yandexData.state === yaMarker) {
      authHandler({
        token: yandexData.access_token,
        socialId: null,
        provider: 'Yandex',
      })
    }
  }, [authHandler])

  const handleClick = () => {
    window.location.href = `https://oauth.yandex.ru/authorize?response_type=token&state=${yaMarker}&client_id=${clientID}&redirect_uri=${getRedirectUri(
      host,
    )}`
  }

  return (
    <button onClick={handleClick} className="yandex">
      Яндекс
    </button>
  )
}

export default Yandex

Yandex.propTypes = {
  authHandler: PropTypes.func.isRequired,
}
