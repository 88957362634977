import React from 'react'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useMutateHardBanUser } from '../../api/administrator'

function UserHardBan({ user }) {
  const { mutate: hardBanUser } = useMutateHardBanUser()

  const handleClick = () => {
    hardBanUser({
      followerId: user?.follower.id,
      ban: !user?.follower?.hardBanned,
    })
  }

  if (!user?.follower) {
    return null
  }
  return (
    <div className="user-banned">
      <b>Полная блокировка: </b>
      <div>
        <span className={user?.follower?.hardBanned ? 'colorBanned' : 'colorActive'}>
          {user?.follower?.hardBanned ? 'Заблокирован' : 'Активен'}
        </span>
        <Button size="small" type="primary" onClick={handleClick}>
          {user?.follower?.hardBanned ? 'Разблокировать' : 'Заблокировать'}
        </Button>
      </div>
    </div>
  )
}

UserHardBan.propTypes = {
  user: PropTypes.object,
}

export default UserHardBan
