import React, { useState } from 'react'
import { Button, Input } from 'antd'
import PropTypes from 'prop-types'

const MODES = {
  add: 'add',
  save: 'save',
}
function AddAdmin({ addAdmin }) {
  const [mode, setMode] = useState(MODES.add)
  const [email, setEmail] = useState('')
  return (
    <>
      {mode === MODES.add && <Button onClick={() => setMode(MODES.save)}>Добавить</Button>}
      {mode === MODES.save && (
        <>
          <Input
            onChange={(event) => {
              setEmail(event.target.value)
            }}
            style={{ width: '200px' }}
          />
          <Button
            onClick={() => {
              addAdmin({ email })
              setMode(MODES.add)
            }}
            style={{ marginLeft: '1rem' }}
            type="primary"
          >
            Сохранить
          </Button>
          <Button
            onClick={() => {
              setEmail('')
              setMode(MODES.add)
            }}
            style={{ marginLeft: '1rem' }}
          >
            Отменить
          </Button>
        </>
      )}
    </>
  )
}
AddAdmin.propTypes = {
  addAdmin: PropTypes.func,
}
export default AddAdmin
