import React from 'react'
import { useParams } from 'react-router-dom'
import UserPage from './UserPage'
import UsersList from './UsersList'

function Users() {
  const { userId } = useParams()
  return userId ? <UserPage userId={userId} /> : <UsersList />
}

export default Users
