import React, { useEffect, useReducer } from 'react'
import { InputNumber, Button, Input, Row, Col, Typography, message, Spin } from 'antd'
import { simpleReducer } from '../../helpers'
import { useAddLikes } from '../../api/administrator'

const { Text, Title } = Typography

const LIKE_COUNT_ERR = 'Количество лайков только целое число больше 0'
const POST_ID_ERR = 'Введите Id поста'

function AddLikes() {
  const initialState = {
    postId: null,
    count: '',
    error: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)
  const { mutate: addLikes, data: addLikesData, isSuccess, isError, isLoading } = useAddLikes()

  useEffect(() => {
    if (isSuccess && addLikesData?.data) {
      setState(initialState)
      message.success(
        <>
          Добавление лайков выполнено. <br /> Общее число лайков:{' '}
          <b>{addLikesData.data?.likesAfterUpdate}</b>
        </>,
      )
    } else if (isError) {
      message.error('Ошибка сохранения данных')
    }
  }, [isSuccess, isError, addLikesData])

  const handleSave = () => {
    if (!state.error && state.postId) {
      addLikes({
        blogPostId: state.postId,
        likesToAdd: state.count,
      })
    } else if (!state.postId) {
      setState({ error: POST_ID_ERR })
    } else if (!(state.count > 0)) {
      setState({ error: LIKE_COUNT_ERR })
    }
  }

  return (
    <div className="addLikes">
      <Title level={3}>Добавление лайков к посту</Title>
      <Spin spinning={isLoading}>
        <Row>
          <Col>
            Id поста:
            <Input
              onChange={(event) => {
                setState({ postId: event.target.value })
              }}
              allowClear
              value={state.postId}
            />
            лайков:
            <InputNumber
              onChange={(value) => {
                if (Number.isInteger(value) && value > 0) {
                  setState({ count: value, error: null })
                } else {
                  setState({ error: LIKE_COUNT_ERR })
                }
              }}
              value={state.count}
              min={1}
            />
            <Button onClick={handleSave} type="primary" disabled={!state.postId || !state.count}>
              Добавить
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text type="danger">{state.error}</Text>
          </Col>
        </Row>
      </Spin>
    </div>
  )
}

export default AddLikes
