import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { Button, Table, Modal, message } from 'antd'
import { formatDateTime } from '../../helpers/time'
import {
  useInfluencerPayments,
  useMutateDeleteInfluencerPayment,
} from '../../api/influencerPayments'
import { simpleReducer } from '../../helpers'

function IncomeList({ influencerId }) {
  const initialState = {
    processId: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const { data, isLoading } = useInfluencerPayments(
    {
      influencerId,
    },
    {
      enabled: Boolean(influencerId),
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  )

  const {
    mutate: deleteInfluencerPayment,
    isLoading: isLoadingDelete,
    isSuccess: isSuccessDelete,
    isError: isErrorDelete,
  } = useMutateDeleteInfluencerPayment()

  useEffect(() => {
    if (isSuccessDelete) {
      setState({ processId: null })
    } else if (isErrorDelete) {
      setState({ processId: null })
      message.error('Не удалось удалить плтеж')
    }
  }, [isSuccessDelete, isErrorDelete])

  const renderActions = (record) => {
    return (
      <Button
        loading={isLoadingDelete && state.processId === record.id}
        size="small"
        onClick={() => confirm(record)}
      >
        Удалить
      </Button>
    )
  }

  const confirm = (record) => {
    Modal.confirm({
      content: 'Удалить платеж?',
      onOk: () => {
        setState({ processId: record.id })
        deleteInfluencerPayment(record.id)
      },
    })
  }

  const columns = [
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      width: 200,
      render: (date) => formatDateTime(date),
    },
    { title: 'Сумма', dataIndex: 'amount', key: 'amount' },
    { title: 'Ид банковской операции', dataIndex: 'paymentData', key: 'paymentData' },
    { key: 'actions', render: renderActions },
  ]

  return (
    <div className="income-list">
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={data?.data || []}
        rowKey={(record) => record.id}
      />
    </div>
  )
}

IncomeList.propTypes = {
  influencerId: PropTypes.string,
}

export default IncomeList
