import React from 'react'
import PropTypes from 'prop-types'
import { appConfig } from '../../constants/appConfig'

const insider = `${appConfig.cdnPath.iconsSvg}/logo/insider.svg`
const spinner = `${appConfig.cdnPath.iconsSvg}/logo/spinner.svg`

function LogoSpinner({ isLoading = false, withLogo = false, size = 'auto' }) {
  return (
    <div className="logo-spinner">
      <div className={`spinner ${isLoading ? 'loading' : ''} ${withLogo ? '' : 'bg-none'}`}>
        <img src={spinner} alt="" height={size} />
      </div>
      {withLogo && (
        <img
          src={insider}
          height={size}
          alt=""
          style={{ marginLeft: parseInt(size) ? (size / 100) * 33 : 10 }}
        />
      )}
    </div>
  )
}
export default LogoSpinner

LogoSpinner.propTypes = {
  isLoading: PropTypes.bool,
  withLogo: PropTypes.bool,
  size: PropTypes.any,
}
