import React, { useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Typography } from 'antd'
import { formatDate } from '../../helpers/time'
import { useRenewLogs } from '../../api/administrator'
import { simpleReducer } from '../../helpers'
import ListTool from '../../Components/Profile/ListTool'
import { appConfig } from '../../constants/appConfig'

const { Title } = Typography

function Main() {
  const history = useHistory()
  console.log('history', history)

  const initialState = {
    fromDate: null,
    toDate: null,
  }
  const [state, setState] = useReducer(simpleReducer, initialState)

  const { data } = useRenewLogs({
    fromDate: state.fromDate?.format(appConfig.dateFormats.apiDateTimeT3),
    toDate: state.toDate?.format(appConfig.dateFormats.apiDateTimeAllDay),
  })

  const handleRange = (value) => {
    if (value) {
      const [fromDate, toDate] = value
      setState({ fromDate, toDate })
    } else {
      setState({ fromDate: null, toDate: null })
    }
  }

  const columns = [
    {
      title: 'Дата',
      dataIndex: 'createDate',
      key: 'createDate',
      render: (createDate) => {
        return <>{formatDate(createDate)}</>
      },
    },
    {
      title: 'Инфлюенсер',
      dataIndex: 'influencerNickName',
      key: 'influencerNickName',
    },
    {
      title: '1 мес',
      dataIndex: 'success1Month',
      key: 'success1Month',
    },
    {
      title: '3 мес',
      dataIndex: 'success3Month',
      key: 'success3Month',
    },
    {
      title: '12 мес',
      dataIndex: 'success12Month',
      key: 'success12Month',
    },
    {
      title: 'Не выполненые',
      dataIndex: 'recurrentLeft',
      key: 'recurrentLeft',
    },
    {
      title: 'Выключен рекуррент',
      dataIndex: 'nonRecurrentLeft',
      key: 'nonRecurrentLeft',
    },
  ]
  return (
    <div className="users">
      <Title level={3}>Выполненные рекуррентные платежи</Title>
      <ListTool onChangeRange={handleRange} />
      <Table pagination={false} columns={columns} dataSource={data?.data || []} />
    </div>
  )
}

export default Main
