import React from 'react'
import PropTypes from 'prop-types'
import { DatePicker, Input, Row, Col, Select } from 'antd'
import 'moment/locale/ru'
import { appConfig } from '../../constants/appConfig'

const { RangePicker } = DatePicker
const { Search } = Input
const { Option } = Select

function ListTool({ onChangeRange, onSearch, onChangeType, children = null }) {
  return (
    <Row className="list-tool" gutter={[10, 10]}>
      {typeof onChangeRange === 'function' && (
        <Col>
          <RangePicker
            format={appConfig.dateFormats.shortDate}
            className="list-tool-range"
            onChange={onChangeRange}
          />
        </Col>
      )}

      {typeof onSearch === 'function' && (
        <Col>
          <Search allowClear placeholder="Ник" onSearch={onSearch} className="list-tool-input" />
        </Col>
      )}

      {typeof onChangeType === 'function' && (
        <Col>
          <Select
            onChange={onChangeType}
            className="paymentTypes"
            allowClear
            placeholder="Тип оплаты"
          >
            {Object.values(appConfig.payment.types).map((item) => (
              <Option key={item} value={item}>
                {appConfig.payment.typeLabels[item]}
              </Option>
            ))}
          </Select>
        </Col>
      )}

      {children}
    </Row>
  )
}

export default ListTool

ListTool.propTypes = {
  onChangeRange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onChangeType: PropTypes.func.isRequired,
  children: PropTypes.node,
}
